import { optimizeCaller } from './util/util';
import EventHandler from './_dependencyvendor/event-handler';

const stickyHeader = () => {
  const stickyExist = !!document.querySelector('.ly-page-sticky');

  if (stickyExist) {
    const headerTop = document.querySelector('.ly-header');
    const stickyGnb = document.querySelector('.header-clone__gnb');
    const isShare = document.querySelector('.header-share-button');
    window.addEventListener(
      'scroll',
      optimizeCaller(e => {
        // Scrolling down
        if (window.scrollY > 56) {
          headerTop.classList.remove('header-slide__down');
          headerTop.classList.add('header-slide__up');
          stickyGnb && stickyGnb.classList.add('is-active');
          isShare && isShare.classList.add('is-active');
          // Scrolling up
        } else {
          headerTop.classList.remove('header-slide__up');
          stickyGnb && stickyGnb.classList.remove('is-active');
          isShare && isShare.classList.remove('is-active');
          headerTop.classList.add('header-slide__down');
        }
      }),
    );
  }
};


/**
 * 버튼이랑 연결된 바텀시트 처리
 */
const floatingWithBottomSheet = () => {
  const buttons = document.querySelectorAll('[data-floating-bs-trigger]');
  const bsDic = new Map();
  if (buttons.length > 0) {
    buttons.forEach(button => {
      EventHandler.on(button, 'click', e => {
        const me = e.currentTarget;
        const isActive = me.getAttribute('data-deactive');
        if (isActive === 'true') return;

        me.setAttribute('data-deactive', 'true');
        e.preventDefault();
        const bsName = e.currentTarget.getAttribute('data-floating-bs-trigger');
        const bsTarget = document.querySelector(bsName);
        if (!bsTarget) {
          console.warn(`[ ${bsName} ] 으로 선언된 BottomSheet를 찾지 못했습니다.`);
        }

        me.classList.add('is-deactive');
        setTimeout(() => {
          if (!bsDic.get(bsTarget)) {
            const bs = new AIA.Dialog(bsTarget);
            bsDic.set(bsTarget, bs);
          }
          bsDic.get(bsTarget).open();
          EventHandler.one(bsTarget, 'ui.dialog.opened', () => {
            me.classList.remove('is-deactive');
            me.classList.remove('is-active');
          });
          EventHandler.one(bsTarget, 'ui.dialog.closed', () => {
            // me.querySelector('button').focus();
            me.classList.add('is-active');
            me.removeAttribute('data-deactive');
            if (button.hasFocus) {
              button.focus();
            } else {
              button.querySelector('button,a,input').focus();
            }
          });
        }, 250);
      });
    });
  }
};

/**
 * landscape 방지 orient 이벤트 핸들러
 */
const orientationLandscapeBan = () => {
  orientationchangeHandler();
  window.addEventListener("orientationchange", orientationchangeHandler);
  
  function orientationchangeHandler(e){
    if(window.orientation == 90 | window.orientation == -90) {
      new AIA.AlertOrientation("모바일 AIA 다이렉트는<br>세로모드에 최적화 되어 있습니다.", {}, 'c-icon--alert-info')
    } else {
      // const modal = AIA.Dialog.getInstance(document.querySelector(".c-modal.is-active"));
      // if(modal) modal.close();
      const instances = AIA.Dialog.getInstances();
      for(var i=0; i<instances.length; i++){
        const dialog = instances[i]._element.firstElementChild.classList.contains('c-modal__dialog--alert-orient');
        if(dialog) instances[i].close();
      }
    }
  }
}

/**
 * input 포커스 시 상위 dom 요소 class 처리
 */
const inputGroupFocus = () => {
  const setVh = () => {
    document.documentElement.style.setProperty('--vh', `${window.visualViewport.height * 0.01}px`);
    document.documentElement.style.setProperty('--wfull', `${document.body.offsetWidth}px`);
  };
  window.addEventListener('resize', setVh);
  setVh();
  EventHandler.on(document.body, 'focus', 'input', e => {
    const target = e.target;
    if (target.nodeName !== `INPUT`) return;
    if (target.focusTarget === undefined) target.focusTarget = findBubblingTarget(target);
    if (target.focusTarget !== null) {
      target.focusTarget.classList.add('is-focus');
    }
  });
  EventHandler.on(document.body, 'focusout', 'input', e => {
    const target = e.target;
    if (
      target.nodeName !== `INPUT` ||
      target.focusTarget === null ||
      target.focusTarget === undefined
    )
      return;
    target.focusTarget.classList.remove('is-focus');
  });
  function findBubblingTarget(value) {
    let tmp = value;
    while (tmp.getAttribute('data-focus') === null) {
      if (tmp === document.body) return null;
      tmp = tmp.parentElement;
    }
    return tmp;
  }
};

/**
 * input 포커스 시 확인 버튼 소프트 키보드 위로 위치 시키는 스크립트
 * data-input-follow 속성 연계
 */
const inputFocus = () => {
  let timeoutID = null;
  let windowScrollY = null;
  let startY = null;
  let follower = null;
  let inputer = null;
  let ready = false;

  const sizeMap = {};

  let age = 0;
  if(window.deviceInfo.isAndroid){
    document.body.addEventListener('focus', focusHandler, true);
    EventHandler.on(document.body, 'blur', 'input', blurHandler);
    // window.visualViewport.addEventListener('resize', resizeHandler);

  }else if(window.deviceInfo.isiOS){
    EventHandler.on(document.body, 'focus', 'input', focusHandleriOS);
    EventHandler.on(document.body, 'keydown', 'input', inputHandleriOS);
    EventHandler.on(document.body, 'blur', 'input', blurHandleriOS);
  }
  else{ 
    // todo 아래는 확인용 코드 release 시 remove 해야 함 
    // document.body.addEventListener('focus', focusHandler, true);
    // EventHandler.on(document.body, 'blur', 'input', blurHandler);
    // window.visualViewport.addEventListener('resize', resizeHandler);
  }
  
  function resizeHandler(e) {
    if (document.activeElement.nodeName === 'INPUT') {
      const activeInput = document.activeElement;
      androidFocus(activeInput);
    }
  }
  function androidFocus(activeInput) {
    if (window.deviceInfo.isAndroid) {
      // let cInput = findParentClassItem(activeInput, 'c-input');
      // if (cInput === null) cInput = findParentClassItem(activeInput, 'c-select');
      const cInput = activeInput;
      const modal = findParentClassItem(activeInput, 'c-modal');
      const ciGroup = findParentClassItem(activeInput, 'c-input-group');
      const inputRect = ciGroup
        ? ciGroup.getBoundingClientRect()
        : activeInput.getBoundingClientRect();

      const inputSize = inputRect.height + inputRect.y;
      const fixedBtn = follower;
      const addSize = fixedBtn ? fixedBtn.offsetHeight : 0;
      const area = window.visualViewport.height - addSize;
      const scrollY = modal === null ? window.scrollY || window.pageYOffset : modal.querySelector('.c-modal__content').scrollTop;
      const header = document.querySelector('.ly-header');
      const headerSize = header === null ? 0 : header.offsetHeight;
     
      if (inputSize > area) {
        const gap = scrollY + inputSize - area;
        if (modal) {
          modal.querySelector('.c-modal__content').scrollTop = gap + addSize;
        } else {
        
          window.scrollTo({ top: gap + addSize, behavior: 'instant' });
        }
      } else if (inputRect.y < scrollY - headerSize) {
        const gapT = scrollY - headerSize;
        if (modal) {
          modal.scrollTop = gapT;
        } else {
          window.scrollTo({ top: gapT, behavior: 'instant' });
        }
      }
    }
  }
  function focusHandler(e) {
    const target = e.target;
    inputer = target;
    if ((target.nodeName === 'INPUT' && (target.getAttribute('type') === 'text' || target.getAttribute('type') === 'email') ) 
    || target.classList.contains('c-fl')) {
      const followTarget = document.querySelector(
        `[data-input-follow~="#${target.getAttribute('id')}"]`,
      );
      if (followTarget) {
        follower = followTarget;
        followTarget.classList.add('follow-input-btn-fixed');
      }
      androidFocus(target);
    }
    
  }
  function blurHandler(e) {
    const target = e.target;
    const followTarget = document.querySelector(
      `[data-input-follow~="#${target.getAttribute('id')}"]`,
    );
    if (follower) {
      setTimeout(() => {
        if (follower === null) {
          followTarget.classList.remove('follow-input-btn-fixed');
        }
      }, 100);
    }
    follower = null;
  }
  let keyupIntervalId = null;
  function inputHandleriOS(e) {
    const target = e.target;
    if (target.nodeName !== 'INPUT') return;
    let age = 0;
    if (keyupIntervalId !== null) {
      clearInterval(keyupIntervalId);
      keyupIntervalId = null;
    }
    keyupIntervalId = setInterval(() => {
      btnLocateSet();
      if (++age === 20) {
        clearInterval(keyupIntervalId);
        keyupIntervalId = null;
      }
    }, 30);
    // btnLocateSet();
  }
  function focusHandleriOS(e) { // 아이폰 대비 포커스 시 처리
    // if (sizeMap[window.innerHeight] === undefined) sizeMap[window.innerHeight] = {};
    if (follower !== null) return;
    const target = e.target;
    const cModal = findParentClassItem(target, 'c-modal');

    inputer = target;
    blurHandleriOS(e);
    if (target.nodeName !== 'INPUT') return;
    androidFocus(target);
    const followTarget = document.querySelector(
      `[data-input-follow~="#${target.getAttribute('id')}"]`,
    );
    if (followTarget) {
      if (cModal !== null) {
        startY = cModal.querySelector('.c-modal__content').scrollTop;
        cModal.querySelector('.c-modal__content').scrollTop = startY + followTarget.offsetHeight;
      }
      if (keyupIntervalId !== null) {
        clearInterval(keyupIntervalId);
        keyupIntervalId = null;
      }

      follower = followTarget;
      followTarget.classList.add('follow-input-btn');
      followTarget.style['top'] = String(0) + 'px';
      const ft = followTarget.getBoundingClientRect();
      const bt = -ft.y + window.visualViewport.height;
      followTarget.style['top'] = bt + 'px';
      let key = inputer.getAttribute('inputmode');
      if (key === null) key = 'text';
      if (sizeMap[key + window.innerHeight] === undefined) {
        if (timeoutID !== null) clearInterval(timeoutID);
        age = 0;
        timeoutID = setInterval(() => {
          // if (followTarget.classList.contains('follow-input-btn') === false) {
          //   followTarget.classList.add('follow-input-btn');
          // }
          scrollHandler();
          if (cModal !== null) {
            cModal.scrollTop = 0;
          }
          if (++age > 40) {
            ready = true;
            if (window.innerHeight !== window.visualViewport.height) {
              // sizeMap[key + window.innerHeight] = Math.ceil(window.visualViewport.height);
              // localStorage.setItem('sizeMap', JSON.stringify(sizeMap));
            }
            clearInterval(timeoutID);
            timeoutID = null;
            age = 0;
            // alert(startY);
          }
        }, 30);
      } else {
        btnLocateSet();
      }
    } else {
      const unfollow = document.querySelector('.c-modal.is-active .c-modal__footer');
      if (unfollow !== null) {
        // unfollow.style.display = 'none';
      }
    }
    EventHandler.on(window, 'scroll', scrollHandler);
    document.body.addEventListener('touchmove', touchHandler);
    window.visualViewport.addEventListener('resize', e => {
      if (cModal !== null) {
        cModal.scrollTop = 0;
      }
    });
  }
  function blurHandleriOS(e) {// 아이폰 대비 블러 시 처리
    if (follower === null) {
      const unfollow = document.querySelector('.c-modal.is-active .c-modal__footer');
      if (unfollow !== null) {
        unfollow.style.removeProperty('display');
      }
      return;
    }
    EventHandler.off(window, 'scroll');
    document.body.removeEventListener('touchmove', touchHandler);
    const followTarget = follower;
    if (keyupIntervalId !== null) {
      clearInterval(keyupIntervalId);
      keyupIntervalId = null;
    }
    if (followTarget) {
      setTimeout(() => {
        if (follower === null) {
          followTarget.classList.remove('follow-input-btn');
          followTarget.style.removeProperty('top');
        }
      }, 100);
    }
    if (timeoutID) clearInterval(timeoutID);
    // follower.focus();

    startY = null;
    follower = null;
    inputer = null;
    timeoutID = null;
    windowScrollY = null;
    touchmoveY = null;
    ready = false;

    // e.target.value += '|blur';
    // EventHandler.off(window, 'scroll');
  }
  // let prevScroll;
  function scrollHandler(e) {
    if (follower && inputer) {
      androidFocus(inputer);
      btnLocateSet();
    }
    if (ready === false) return;
    if (windowScrollY === null) {
      windowScrollY = window.scrollY || window.pageYOffset;
      return;
    }
    if (Math.abs(window.scrollY - windowScrollY) > 3) {
      inputer.blur();
    }
  }
  function btnLocateSet() { // 돔요소 소프트 키보드 위로 강제 위치
    const key =
      inputer.getAttribute('inputmode') === null ? 'text' : inputer.getAttribute('inputmode');

    

    const hv = window.visualViewport.height;
    if (follower === null) return;
    follower.style['top'] = '0px';
    const ft = follower.getBoundingClientRect();
    const bt = -ft.y + hv - follower.offsetHeight;
    follower.style['top'] = bt + 'px';

    // inputer.value = window.innerHeight + '|' + window.visualViewport.height + '|' + hv;
  }
  let touchmoveY = null;
  function touchHandler(e) {
    if (touchmoveY === null) {
      touchmoveY = e.touches[0].clientY;
      return;
    }
    if (Math.abs(e.touches[0].clientY - touchmoveY) > 40) {
      if (inputer) inputer.blur();
    }
  }
  function findParentClassItem(elemet, className) {
    let tmp = elemet;
    while (tmp.classList.contains(className) === false) {
      tmp = tmp.parentElement;
      if (tmp === document.body) return null;
    }
    return tmp;
  }
};

/**
 * 해더 드롭 다운 메뉴 확장 비확장 스크립트
 */
const headerDropDownSet = () => {
  const headerBtn = document.querySelector('.c-header-button');
  const headerDropDown = document.querySelector('.ly-header .c-dropdown');
  const header = document.querySelector('.ly-header');
  if (headerBtn && headerDropDown) {
    EventHandler.on(headerBtn, 'click', e => {
      if (headerDropDown.classList.contains('is-active')) {
        header.classList.remove('is-active');
        headerBtn.classList.remove('is-active');
        headerDropDown.classList.add('is-deactive');

        EventHandler.one(headerDropDown, 'animationend', () => {
          headerDropDown.classList.remove('is-deactive');
          headerDropDown.classList.remove('is-active');
          
        });
        
        AIA.Dialog.ClosePopEvent(headerDropDown);
      } else {
        header.classList.add('is-active');
        headerBtn.classList.add('is-active');
        headerDropDown.classList.add('is-active');
        EventHandler.one(headerDropDown, 'animationend', () => {
        
        });
        AIA.Dialog.OpenPopEvent(headerDropDown);
      }
    });
    EventHandler.on(headerDropDown, 'click', e => {
      if (e.target.classList.contains('c-dropdown')) {
        if (e.target.classList.contains('is-deactive') === false) {
          EventHandler.trigger(headerBtn, 'click');
        }
      }
    });
  }
};

/**
 * 리드젠 상담 플롯팅 버튼 클릭 시 확장 처리 및 레이아웃 관련 스크립트
 */
const leadgenBottomBtnSet = () =>{
  var extendSize = null
  const btnconsultItems = document.querySelectorAll(".btn-consult + .consult .consult__item .c-button");
  const btnConsultTg = document.querySelector(".ly-footer__button--type2 .btn-consult") || document.querySelector(".ly-content__foot .btn-consult");
  // const stkBtnConsultTg = document.querySelector(".ly-content__foot.sticky .btn-consult");
 
  posBtn();
  setChildtabindex(false);
  function posBtn() {
    const footerBtnLeft = document.querySelector(".ly-footer__button--type2");
    const footerBtnRight = document.querySelector(".ly-footer__button--type3");
    
    if(footerBtnLeft){
      const getMargin = window.getComputedStyle(document.querySelector(".ly-wrap .ly-content")).marginLeft;
      footerBtnLeft.style.left = getMargin;
    }
    if(footerBtnRight){
      const getMargin = window.getComputedStyle(document.querySelector(".ly-wrap .ly-content")).marginLeft;
      const getContW = window.getComputedStyle(document.querySelector(".ly-wrap .ly-content")).width;
      if(extendSize === null) extendSize = window.getComputedStyle(footerBtnRight).width;
     
      const getThisW = extendSize;
      footerBtnRight.style.left = parseInt(getMargin) + parseInt(getContW) - parseInt(getThisW) + 'px';
    }
  };
  window.addEventListener("resize", posBtn);
  let delayID = null;
  function toggle(elm, cls, delay) {
    const footerBtnRight = document.querySelector(".ly-footer__button--type3");
    const footerBtnleft = document.querySelector(".ly-footer__button--type2");
    if(delayID !== null) clearTimeout(delayID);
    if (elm.classList.contains(cls)) {
      elm.classList.remove(cls);
      remDim();
      if(footerBtnRight){
        footerBtnRight.style.removeProperty('z-index');
        AIA.Dialog.ClosePopEvent(footerBtnRight);
      }
      if(footerBtnleft){
        footerBtnleft.style.removeProperty('width');
      }
      
      
    } else {
      elm.classList.add(cls);
     
      if(footerBtnleft){
        footerBtnleft.style.width = '5rem';
      }
      if(footerBtnRight){
        footerBtnRight.style['z-index'] = 7;
        if(delay === undefined){
          AIA.Dialog.OpenPopEvent(footerBtnRight);
        }else{
          delayID = setTimeout(() => {
            AIA.Dialog.OpenPopEvent(footerBtnRight);
            delayID = null;
          }, delay);
        }
      }
      
      EventHandler.off(document.body, 'focus', 'button');
      mkDim();
    }
  }
  function mkDim() {
    if (document.querySelector(".dim") == null) {
      var newDiv = document.createElement("div");
      newDiv.classList.add("dim");
      newDiv.classList.add("fadein");
      EventHandler.on(newDiv, 'click', (e) => {
        const btnConsultTg = document.querySelector(".btn-consult");
        const btnfooters = btnConsultTg.closest('.ly-content__foot');
        toggle(btnConsultTg,  "is-active");
        if(btnfooters){
          btnfooters.classList.remove('rm-bg')
        }
        EventHandler.off(document.body, 'focus', 'button');
      })
      document.body.appendChild(newDiv);
    }
  }
  function remDim() {
    const dimDom = document.querySelector(".dim")
    if (dimDom !== null) {
      EventHandler.off(dimDom, 'click', (e) => {
        const btnConsultTg = document.querySelector(".ly-content__foot .btn-consult");
        const btnfooters = btnConsultTg.closest('.ly-content__foot');
        toggle(btnConsultTg,  "is-active");
        if(btnfooters){
          btnfooters.classList.add('rm-bg')
        }
      })
      document.querySelector(".dim").remove();
    }
  }

  if(btnConsultTg){
    
    EventHandler.on(btnConsultTg, "click", (e) =>{
      const target = e.currentTarget;
      const btnfooters = target.closest('.ly-content__foot');
      let delay;
      
      if(btnfooters){
        if(btnfooters.classList.contains('rm-bg')){
          btnfooters.classList.remove('rm-bg')
        }else{
          btnfooters.classList.add('rm-bg')
        }
        const delayBool = stickyBtnWindowScroll();
        if(stickyBtnWindowScroll()){
          delay = 500;
        }
      }
      toggle(target, "is-active", delay);
      if(target.classList.contains("is-active")){
        EventHandler.on(document.body, 'focus', 'button', focusHandler);
      }else{
        EventHandler.off(document.body, 'focus', 'button');
      }
      setChildtabindex(target.classList.contains("is-active"))
      
    }); 
  }
  
  if(btnconsultItems){
    btnconsultItems.forEach( (el) =>{
      EventHandler.on(el,"click", (e) => {
        if(btnConsultTg) {
          EventHandler.trigger(btnConsultTg,'click');
        }
        // if(stkBtnConsultTg) {
        //   EventHandler.trigger(stkBtnConsultTg,'click');
        // }
      });
    });
  }

  

  // top버튼
  const btnTop = document.querySelector("#btnTop");
  if(btnTop) {
    function tgBtnTop() {
      if(document.documentElement.scrollTop <= 0) {
        setTimeout(function() {
          btnTop.classList.add("is-deactive");
        }, 500);
        btnTop.classList.remove("is-active");
      } else {
        btnTop.classList.remove("is-deactive");
        btnTop.classList.add("is-active");
      }
    }
    function posBtnTop() {
      const stkBtnWrap = document.querySelector(".ly-content__foot.sticky");
      if(stkBtnWrap) {
        if(document.documentElement.scrollTop >= document.querySelector(".ly-footer").offsetTop - window.innerHeight + document.querySelector(".ly-content__foot.sticky").offsetHeight) {
          btnTop.closest(".ly-footer__button").style.bottom = '24px';
        } else {
          btnTop.closest(".ly-footer__button").style.bottom = stkBtnWrap.offsetHeight - 10 + 'px';
        }
      } else {
        btnTop.closest(".ly-footer__button").style.bottom = '24px';
      }
    }
    tgBtnTop();
    posBtnTop();
    window.addEventListener("scroll", function() {
      tgBtnTop();
      posBtnTop();
    });
    EventHandler.on(btnTop, "click", (e) =>{
      window.scrollTo(0, 0);
    });
  }
  function stickyBtnWindowScroll(){
    const target = btnConsultTg;
    const footers = document.querySelector(".ly-consider") || document.querySelector('.ly-footer');
    const footerRect = footers.getBoundingClientRect();
    
    if(footerRect.y < window.innerHeight) {
      window.scrollTo(0, document.querySelector(".ly-footer").offsetTop - window.innerHeight);
      return true;
    }
    
    return false;
  }
  function focusHandler(e) {
    // 접근성 고려 외부 요소 포커스 되었을때 처리
    const target = e.target;
    const len = btnconsultItems.length;
    for(let i =0; i < len; i++){
      if(btnconsultItems[i] === target) return;
    }
    if(target === btnConsultTg) return;
    EventHandler.trigger(btnConsultTg,'click');
    EventHandler.off(document.body, 'focus', 'button');
  }
  function setChildtabindex(boolean){
    btnconsultItems.forEach( (el) =>{
      if(boolean){
        el.removeAttribute('tabindex')
      }else{
        el.setAttribute('tabindex', -1)
      }
    });

  }
}

/**
 * 스위치형 input[type='checkbox'] css 관련 스크립트 처리 
 */
const basicSwitchFunc = () => {
  const labelSwitch = document.querySelectorAll('.c-checkbox--switch-type2');
  labelSwitch.forEach(el => {
    const spans = el.querySelectorAll('span');
    if (spans.length < 2) return;
    spans[1].style.display = 'none';
    const active = spans[0].innerHTML;
    const deactive = spans[1].innerHTML;
    spans[0].innerHTML = el.querySelector('input').checked ? active : deactive;
    el.addEventListener('change', e => {
      // const label = el.nextElementSibling.querySelector('span');
      // e.target.checked ? (label.innerText = '가입') : (label.innerText = '미가입');
      if (e.target.checked) {
        spans[0].innerHTML = active;
      } else {
        spans[0].innerHTML = deactive;
      }
    });
  });
};

/**
 * 공통 버튼 c-button 터치시 is-down 터치 아웃 시 is-down 해제
 */
const cButtonDownUpInteraction = () =>{
  document.body.addEventListener('touchstart', (e)=>{
    const target = findBubblingTarget(e.target);
    if(target === null  || target.classList.contains('c-button') === false) return;
    target.classList.add('is-down');
  })
  document.body.addEventListener('touchend', (e)=>{
    const target = findBubblingTarget(e.target);
    if(target === null  || target.classList.contains('c-button') === false) return;
    target.classList.remove('is-down');
  });
  function findBubblingTarget(value) {
    let tmp = value;
    while (tmp !== document.body) {
      if(tmp.classList.contains('c-button')) return tmp
      tmp = tmp.parentElement;
    }
    return tmp;
  }
}
/**
 * 스텝이 포함 된 페이지에서 상단 게이지 바 움직임 처리 스크립트
 */
const stepGaugeSet = () => {
  AIA.nextStep = updateCheck;
  const gauge = document.querySelector('.c-steps.c-steps--type3');
  
  updateCheck();
  function updateCheck(){
    if(gauge === null) return;
    let currentGauge = document.querySelectorAll('.c-steps__step.is-active');
    currentGauge = currentGauge[currentGauge.length - 1];
    let age = 0;
    if(currentGauge === document.querySelectorAll('.c-steps__step')[0]){
      age += 25;
    }
    const caseMsgs = document.querySelectorAll('.c-msg-area.event-msg .case')
    let index  = 0;
    caseMsgs.forEach((mem, i)=>{
      if(mem.classList.contains('is-hidden') === false){
        index =  i;
      }
    })
    const endValue = 25 / caseMsgs.length * index;
    age += endValue;
    currentGauge.style.flex = `0 0 ${age}%`
  };
}
// 이곳에서....
const initFunc = () => {
  // 헤더 고정
  stickyHeader();

  // 플로팅 버튼으로 바텀시트 노출
  floatingWithBottomSheet();
  
  // Landscape(가로모드) 방지 alert 코드
  orientationLandscapeBan();
  
  // input 그룹일 경우 포커스 처리
  inputGroupFocus();

  // input 포커스 되었을때 핸들링 처리
  inputFocus();

  // 헤더 드랍 다운
  headerDropDownSet();

  // 하단 플로팅 상담 확장 버튼
  leadgenBottomBtnSet();

  // 스위치 기본 기능
  basicSwitchFunc();

  // 버튼 down, up interaction 추가
  cButtonDownUpInteraction();

  // 보험 가입 단계 표시 게이지
  stepGaugeSet();
  
};


const commonFunc = {
  initFunc,
};

export default commonFunc;
