import UI from './ui-base';
import EventHandler from '../_dependencyvendor/event-handler';

class UnitDomLoader extends UI {
    constructor(element, config = {}) {
      super(element, config);
      this._element = element;
      this._init();
    }
    _init(){
      const prevSetAttri = this._element.setAttribute;
      const me = this;
      this._makeDiv()
    }
    _attributeSet(key, value){
      this.prevSetAttri(key, value);
    }
    _makeDiv(){
      const div = document.createElement('div');
      const gauge = document.createElement('div');
      div.classList.add('c-partition-loading');
      gauge.classList.add('c-loading-gauge');
      div.appendChild(gauge);
      this._loaderDom = div;
      this._element.style.position = 'relative';
       this._element.style.display = 'block';
    }
    loadStart(){
      if(this._heightSize === undefined){
        let heightSize =  this._element.offsetHeight;
        if(heightSize === 0){
          this._element.textContent = 'test';
          heightSize =  this._element.offsetHeight;
        }
        this._element.style.height = heightSize + 'px';
        this._element.textContent = '';
        
        this._heightSize = heightSize;
      }
      if(this._loaderDom.parentElement === null){
        this._element.appendChild(this._loaderDom);
      }
    }
    set(value){
      this._element.removeChild(this._loaderDom);
      this._element.innerHTML = value;
    }

}

export default UnitDomLoader;