/**
 * Dialog Wrapping Class
 * static Element 기반으로 동작하는 Dialog에
 * element를 동적으로 주입하여 사용
 *
 * 한번 쓰고 버릴 Alert, Confrim popup에 사용
 */

import { isEmptyObject } from '../util/object-util';
import { toHTML } from '../util/dom-util';
import Dialog from './dialog';

class DialogHOC {
  constructor(
    config = {
      layout: ``,
      replacer: {},
      zIndexIgnore: false,
      bg:true,
    },
  ) {
    if (config.layout === '') {
      throw Error(`템플릿이 설정되지 않았습니다!`);
    }
    if (isEmptyObject(config.replacer)) {
      console.warn(`리플레이서가 설정되지 않으면 출력되는 데이터가 올바르지 않을 수 있습니다.`);
    }
    config.zIndexIgnore = config.zIndexIgnore === undefined? false:config.zIndexIgnore;
    config.bg = config.bg === undefined? true:config.bg;
    this._instance = null;
    this._element = this._createElement(config);
    document.querySelector('.modal-container').appendChild(this._element);
    // 다이나믹한 컨텐츠는 바로 삭제
    this._instance = new Dialog(this._element, {
      destroy: true,
      zIndexIgnore:config.zIndexIgnore,
      bg:config.bg, 
    });
    return this._instance;
  }

  open() {
    this._instance.open();
  }

  close() {
    this._instance.close();
  }

  getElement() {
    return this._element;
  }

  _createElement(config) {
    const { layout, replacer } = config;
    let source = layout;
    for (const key in replacer) {
      if (Object.prototype.hasOwnProperty.call(replacer, key)) {
        source = source.replaceAll(key, replacer[key]);
      }
    }
    return toHTML(source);
  }
}

export default DialogHOC;
