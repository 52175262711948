/* eslint-disable new-cap */
import 'element-closest-polyfill';
// import 'custom-event-polyfill';
import { getRandomID } from './util/string-util';
// import { toggleHelper, expandHelper } from './util/data-helper';
import EventHandler from './_dependencyvendor/event-handler';
import NavigateAllMenu from './ui/all-menu';
import Tab from './ui/tab';
import Accordion from './ui/accordion';
import Tooltip from './ui/tooltip';
import Dialog from './ui/dialog';
import common from './common';
import DialogHOC from './ui/dialog-hoc';
import LoadingSpinner from './ui/loading-spinner';
import LazyLoading from './ui/loading-lazy';
import Toast from './ui/toast';
import TooltipBox from './ui/tooltip-box';
import InputComponent from './ui/input-comp';
import BottomSheetSelect from './ui/bs-select';
import AgreementFloat from './ui/agreement';
import UnitDomLoader from './ui/unit-dom-loader';
import { isVisible } from './util/dom-util';
import { isEmptyObject } from './util/object-util';
import data from './_dependencyvendor/data';

// import SGame from './game/s-game';

/**
 * UI 초기화 처리
 * @param {*} target
 * @param {*} UI
 * @param {*} options
 */
const UIInitializer = (target, UI, options = {}) => {
  const elements = document.querySelectorAll(target);
  elements.forEach(el => {
    const isIgnore = !!el.getAttribute('data-ignore');
    if (!isIgnore) {
      if (!UI.getInstance(el)) {
        new UI(el, options);
      }
    }
  });
};

const globalConfigSetup = () => {
  Dialog.GLOBAL_CONFIG = {
    openClass: 'is-active',
    closeClass: 'is-deactive',
  };
  Tab.GLOBAL_CONFIG = {
    activeClass: 'is-active',
    initialClass: 'c-tabs',
  };
  Accordion.GLOBAL_CONFIG = {
    activeClass: 'is-active',
    initialClass: 'c-accordion',
  };
  Toast.GLOBAL_CONFIG = {
    templateContainer: `<div class="c-toast" role="alert" aria-live="assertive" aria-atomic="true"></div>`,
    templateBox: `<div class="c-toast__box">{{MESSAGE}}</div>`,
  };
  TooltipBox.GLOBAL_CONFIG = {
    showClass: 'is-active',
    hideClass: 'is-deactive',
  };
};
const getMobileDevice = agent => {
  // let useragent       = navigator.userAgent.toLowerCase();
  const device = {};
  const lowcase = String(agent).toLocaleLowerCase();
  if (navigator.platform) {
    const isiOSPhone = /iphone|ipod/i.test(lowcase);
    const isiOSTablet = /ipad/i.test(lowcase);
    const isiOSTabletNew = /macintosh/i.test(lowcase); // 차세대 아이패드는 기존 iPad와 달리 Mac운영체제를 씀
    const isAndroid = /android/i.test(lowcase);
    const isAndTablet = /tablet/i.test(lowcase);
    const isChrome = /crios/i.test(lowcase);
    device.isiOS = isiOSPhone || isiOSTablet || isiOSTabletNew;
    device.isAndroid = isAndroid || isAndTablet;
    device.isChromeiOS = isChrome;
    device.isPC = /mobi/i.test(lowcase) === false;
    device.isMSTablet =
      window.navigator.maxTouchPoints > 1 && device.isiOS == false && device.isAndroid == false;
    if (device.isiOS) {
      device.isTablet = isiOSTablet || isiOSTabletNew;
    } else {
      device.isTablet = isAndTablet;
    }
    const verStr = forVer(agent);
    if (verStr) device.ver = device.isiOS ? verStr.split('_') : verStr.split('.');
  }
  return device;
  function forVer(agent) {
    const arr = agent.split(' ');
    const len = arr.length;
    for (let i = 0; i < len; i++) {
      const mem = arr[i];
      if (device.isiOS && mem.indexOf('OS') >= 0) {
        return arr[i + 1];
      } else if (device.isAndroid && mem.indexOf('Android') >= 0) {
        return arr[i + 1];
      }
    }
    return null;
  }
};
const initialize = () => {
  // 탭
  window.deviceInfo = getMobileDevice(navigator.userAgent);
  if (window.deviceInfo.isiOS) {
    // 아이폰 OS 15이하에서
    if (Number(window.deviceInfo.ver[0]) < 15) {
      // 아이폰 OS 15이하에서
      document.body.classList.add('c-ios_under_15');
    } else {
      document.body.classList.add('c-ios');
    }
  } else if (window.deviceInfo.isAndroid) {
    document.body.classList.add('c-android');
  } else {
    document.body.classList.add('c-pc');
  }

  UIInitializer('.c-tabs', Tab);
  // 툴팁
  UIInitializer('.c-tooltip', Tooltip, { replacer: { ['data-tooltip-msg']: '{{MESSAGE}}' } });
  // 아코디언
  UIInitializer('.c-accordion:not(.switch)', Accordion);
  // 스위치 아코디언
  UIInitializer('.c-accordion.switch', Accordion, { onlyOne: true });
  // 툴팁박스
  UIInitializer('[data-tpbox]', TooltipBox);
  // INPUT 관련 UI 기능
  UIInitializer('[data-input-com]', InputComponent);
  // select 형 바텀 시트 관련 UI 기능
  UIInitializer('[data-bs-select]', BottomSheetSelect);
  // 플로팅 fixed 동의 창
  UIInitializer('[data-float-agree]', AgreementFloat);

  //텍스트 로더 동적 로더
  UIInitializer('[data-text-loader]', UnitDomLoader);

  // common.initialize();
  NavigateAllMenu.initialize();
};

const Alert = (message, callbacks = {}) => {
  const dialog = new DialogHOC({
    layout: `
      <div role="dialog" aria-describedby="{{a11y}}" class="c-modal" tabindex="-1">
        <div role="document" class="c-modal__dialog c-modal__dialog--alert">
          <div id="{{a11y}}" class="c-modal__content">
            <div class="c-modal__body">
              <p class="c-text c-text--type2">{{message}}</p>
            </div>
            <div class="c-modal__footer">
              <div class="c-btn-wrap">
                <button class="c-button c-button--primary c-button--md" data-dialog-close>확인</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    `,
    replacer: {
      '{{a11y}}': getRandomID(),
      '{{message}}': message,
    },
  });

  if (callbacks.open) {
    EventHandler.one(dialog.getElement(), Dialog.EVENT.OPEN, event => {
      callbacks.open.apply(event.component, []);
    });
  }
  if (callbacks.close) {
    EventHandler.one(dialog.getElement(), Dialog.EVENT.CLOSE, event => {
      callbacks.close.apply(event.component, []);
    });
  }
  if (callbacks.opened) {
    EventHandler.one(dialog.getElement(), Dialog.EVENT.OPENED, event => {
      callbacks.opened.apply(event.component, []);
    });
  }
  if (callbacks.closed) {
    EventHandler.one(dialog.getElement(), Dialog.EVENT.CLOSED, event => {
      callbacks.closed.apply(event.component, []);
    });
  }
  dialog.open();
};
const AlertWidthIcon = (message, callbacks = {}, iconClass) => {
  const _iconClass = iconClass === undefined ? 'c-icon--alert-confirm' : iconClass;
  const dialog = new DialogHOC({
    layout: `
      <div role="dialog" class="c-modal" aria-describedby="{{a11y}}" tabindex="-1">
        <div role="document" class="c-modal__dialog c-modal__dialog--alert-type2">
          <div id="{{a11y}}" class="c-modal__content">
            <div class="c-modal__body">
              <i class="${_iconClass}"></i>
              <p class="c-text c-text--type2">{{message}}</p>
            </div>
            <div class="c-modal__footer">
              <div class="c-btn-wrap">
                <button class="c-button c-button--primary c-button--md" data-dialog-close>확인</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    `,
    replacer: {
      '{{a11y}}': getRandomID(),
      '{{message}}': message,
    },
  });
  if (callbacks.open) {
    EventHandler.one(dialog.getElement(), Dialog.EVENT.OPEN, event => {
      callbacks.open.apply(event.component, []);
    });
  }
  if (callbacks.close) {
    EventHandler.one(dialog.getElement(), Dialog.EVENT.CLOSE, event => {
      callbacks.close.apply(event.component, []);
    });
  }
  if (callbacks.opened) {
    EventHandler.one(dialog.getElement(), Dialog.EVENT.OPENED, event => {
      callbacks.opened.apply(event.component, []);
    });
  }
  if (callbacks.closed) {
    EventHandler.one(dialog.getElement(), Dialog.EVENT.CLOSED, event => {
      callbacks.closed.apply(event.component, []);
    });
  }
  dialog.open();
};
const AlertOrientation = (message, callbacks = {}, iconClass) => {
  const _iconClass = iconClass === undefined ? 'c-icon--alert-info' : iconClass;
  const dialog = new DialogHOC({
    layout: `
      <div role="dialog" class="c-modal landscape-ban-screen" aria-describedby="{{a11y}}" tabindex="-1">
        <div role="document" class="c-modal__dialog c-modal__dialog--alert-orient">
          <div id="{{a11y}}" class="c-modal__content">
            <div class="c-modal__body">
              <i class="${_iconClass}"></i>
              <p class="c-text c-text--type2">{{message}}</p>
            </div>
          </div>
        </div>
      </div>
    `,
    replacer: {
      '{{a11y}}': getRandomID(),
      '{{message}}': message,
    },
    zIndexIgnore: true,
    bg: false,
  });

  if (callbacks.open) {
    EventHandler.one(dialog.getElement(), Dialog.EVENT.OPEN, event => {
      callbacks.open.apply(event.component, []);
    });
  }
  if (callbacks.close) {
    EventHandler.one(dialog.getElement(), Dialog.EVENT.CLOSE, event => {
      callbacks.close.apply(event.component, []);
    });
  }
  if (callbacks.opened) {
    EventHandler.one(dialog.getElement(), Dialog.EVENT.OPENED, event => {
      callbacks.opened.apply(event.component, []);
    });
  }
  if (callbacks.closed) {
    EventHandler.one(dialog.getElement(), Dialog.EVENT.CLOSED, event => {
      callbacks.closed.apply(event.component, []);
    });
  }
  dialog.open();
  // dialog._bg.style["background-color"] = "rgba(0, 0, 0, 0.85)";
};
const Confirm = (message, confirmCallback = null) => {
  const dialog = new DialogHOC({
    layout: `
    <div role="dialog" aria-describedby="{{a11y}}" class="c-modal" tabindex="-1">
    <div role="document" class="c-modal__dialog c-modal__dialog--alert">
      <div id="{{a11y}}" class="c-modal__content">
        <div class="c-modal__body">
          <p class="c-text c-text--type2">{{message}}</p>
        </div>
        <div class="c-modal__footer">
          <div class="c-btn-wrap">
            <button class="c-button c-button--md c-button--gray" data-dialog-close>취소</button>
            <button class="c-button c-button--md c-button--primary" data-dialog-confirm>확인</button>
          </div>
        </div>
      </div>
    </div>
  </div>
    `,
    replacer: {
      '{{a11y}}': getRandomID(),
      '{{message}}': message,
    },
  });

  EventHandler.one(dialog.getElement(), Dialog.EVENT.OPEN, event => {
    const confirm = dialog.getElement().querySelector('[data-dialog-confirm]');
    const cancel = dialog.getElement().querySelector('[data-dialog-close]');
    let confirmFunc = null;
    let cancelFunc = null;
    if (confirmCallback !== null) {
      confirmFunc =
        typeof confirmCallback === 'function' ? confirmCallback : confirmCallback.confirm;
      cancelFunc = confirmCallback.cancel === undefined ? null : confirmCallback.cancel;
    }
    if (confirm) {
      EventHandler.one(confirm, 'click', () => {
        if (confirmFunc) {
          confirmFunc(event.component);
        }
        event.component.close();
      });
    }
    if (cancel) {
      EventHandler.one(cancel, 'click', () => {
        if (cancelFunc) {
          cancelFunc(event.component);
        }
      });
    }
  });
  dialog.open();
};
document.addEventListener('DOMContentLoaded', () => {
  let windowScrollY = 0;
  EventHandler.on(window, Dialog.EVENT.FIRST_OPEN, () => {
    windowScrollY = window.scrollY || window.pageYOffset;
    const contentsQry = '.guide-wrap, .ly-wrap';
    const contentDom = document.body.querySelector(contentsQry);

    if (window.deviceInfo.isiOS) {
      document.body.classList.add('fixed');
      if (contentDom.offsetHeight > window.visualViewport.height) {
        document.querySelector('html').style.height = '100vh';
      } else {
        document.querySelector('html').style.height = '100%';
      }
      document.body.style.height = window.innerHeight + 'px';
      document.body.style['margin-top'] = -windowScrollY + 'px';
    } else {
      document.body.style.overflow = 'hidden';
    }
  });

  EventHandler.on(window, Dialog.EVENT.LAST_CLOSE, () => {
    if (window.deviceInfo.isiOS) {
      document.body.classList.remove('fixed');
      document.body.style.removeProperty('height');
      document.body.style.removeProperty('margin-top');
      document.querySelector('html').style.removeProperty('height');
    } else {
      document.body.style.removeProperty('overflow');
    }
    // window.scrollTo(0, windowScrollY);
    window.scrollTo({ top: windowScrollY, behavior: 'instant' });
  });

  globalConfigSetup();
  initialize();
  common.initFunc();
  EventHandler.trigger(document, 'UILoaded');
  console.log('UI Initialized!');
});

window.AIA = {
  initialize,
  Tab,
  Tooltip,
  Accordion,
  Dialog,
  Alert,
  AlertWidthIcon,
  AlertOrientation,
  Confirm,
  LoadingSpinner,
  LazyLoading,
  Toast,
  TooltipBox,
  InputComponent,
  BottomSheetSelect,
  AgreementFloat,
  UnitDomLoader,
  EventHandler,
  NavigateAllMenu,
  isVisible,
};
export {
  initialize,
  Tab,
  Accordion,
  Tooltip,
  Dialog,
  Alert,
  AlertWidthIcon,
  AlertOrientation,
  Confirm,
  LoadingSpinner,
  LazyLoading,
  Toast,
  TooltipBox,
  InputComponent,
  BottomSheetSelect,
  AgreementFloat,
  UnitDomLoader,
};
