export const optimizeCaller = callback => {
  let tick = false;
  return () => {
    if (tick) return;
    tick = true;
    return window.requestAnimationFrame(() => {
      tick = false;
      return callback();
    });
  };
};
