import UI from './ui-base';
import EventHandler from '../_dependencyvendor/event-handler';
import Dialog from './dialog';

class AgreementFloat extends UI {
  constructor(element, config = {}) {
    super(element, config);
    this._initVars();
    this._initEvents();
  }
  _initVars() {
    this.innerSize =  window.visualViewport.height;
    this.expandSize = null;
    this.isActived = this._element.classList.contains('is-active');
    this.isExpanded = this._element.classList.contains('is-expanded');
  }
  _initEvents() {
    const toggler = this._element.querySelector('[data-toggler]');
    EventHandler.on(toggler, 'click', this._toggleHandler.bind(this));
    EventHandler.on(this._element, 'click', this._bodyClickHandler.bind(this));
    EventHandler.on(window, 'resize', this._resizeHandler.bind(this));
    EventHandler.on(this._element, 'change', this._changeHandler.bind(this));
    const scroller = this._element.querySelector('.c-floating__body');
    EventHandler.on(scroller, 'scroll', this._scrollHandler.bind(this));
  }
  _removeEvents(){
    const toggler = this._element.querySelector('[data-toggler]');
    EventHandler.off(toggler, 'click');
    EventHandler.off(this._element, 'click');
    EventHandler.off(window, 'resize');
    EventHandler.off(this._element, 'change');
    const scroller = this._element.querySelector('.c-floating__body');
    EventHandler.off(scroller, 'scroll');
  }
  _scrollHandler(e) {
    const scroller = this._element.querySelector('.c-floating__body');
    if (scroller.scrollHeight <= scroller.offsetHeight) return;
    const maxScrollSize = scroller.scrollHeight - scroller.offsetHeight;
    if (scroller.scrollTop > 24) {
      scroller.classList.add('top-blur');
    } else {
      scroller.classList.remove('top-blur');
    }
    if (scroller.scrollTop > maxScrollSize - 30) {
      scroller.classList.remove('bottom-blur');
    } else {
      scroller.classList.add('bottom-blur');
    }
  }
  _changeHandler(e) {
    const check = e.target;
    if (check.getAttribute('data-group-parent') !== null) {
      const parentKey = check.getAttribute('data-group-parent');
      const keys = parentKey.split(' ');
      keys.forEach((mem, i) => {
        const key = mem.trim();
        const selectors = this._element.querySelectorAll(`[data-group-child~="${key}"]`);
        selectors.forEach((dom, j) => {
          dom.checked = check.checked;
        });
      });
    }
    if (check.getAttribute('data-group-child') !== null) {
      const childKey = check.getAttribute('data-group-child');
      const keys = childKey.split(' ');
      const len = keys.length;
      for (let i = len - 1; i >= 0; i--) {
        const mem = keys[i];
        const key = mem.trim();
        const selectors = this._element.querySelectorAll(`[data-group-child~="${key}"]`);
        const boolean = childrenAllCheck(selectors);
        // if(boolean === true)
        const parents = this._element.querySelectorAll(`[data-group-parent~="${key}"]`);
        const jlen = parents.length;
        for (let j = jlen - 1; j >= 0; j--) {
          const dom = parents[j];
          dom.checked = boolean;
        }
      }
    }
    if(this._allCheckConfirm()){
      if (this.isExpanded === false) {this._expand(true);}
    }
    function childrenAllCheck(mems) {
      const len = mems.length;
      for (let i = 0; i < len; i++) {
        const mem = mems[i];
        if (mem.checked === false) return false;
      }
      return true;
    }
  }
  _toggleHandler(e) {
    if (this.isExpanded) {
      this._expand(false);
    } else {
      this._expand(true);
    }
  }
  _bodyClickHandler(e) {
    if (e.target !== this._element) return;
    // const contentDom = this._element.querySelector('[data-body]');
    this._expand(false);
  }
  _resizeHandler(e) {
    const contentDom = this._element.querySelector('[data-body]');
    if(contentDom === null) return;
    if(this.isExpanded) {
      // this._element.classList.add('is-expanded')
      contentDom.style.removeProperty('height');
      
      this.expandSize = contentDom.offsetHeight;
      if(this.expandSize > this._getExtraSize()){
        contentDom.style.height = this._getExtraSize() + 'px';
      }else{
        contentDom.style.height = this.expandSize  + 'px';
      }
    }
    else{
      this._element.classList.add('is-expanded')
      contentDom.style.removeProperty('height');
      this.expandSize = contentDom.offsetHeight;
      this._element.classList.remove('is-expanded');
      contentDom.style.height = '0px';
    }
   
  }
  _allCheckConfirm(){
    const lists = this._element.querySelectorAll('input[type="checkbox"]');
    const len = lists.length;
    let age = 0;
    for(let i = 0; i < len; i++){
      if(lists[i].checked){
        age ++;
      }
    }
    return age === len;
  }
  checkedAll(){
    return this._allCheckConfirm();
  }
  setCheckedAll(checked){
    const lists = this._element.querySelectorAll('input[type="checkbox"]');
    const len = lists.length;
    let age = 0;
    for(let i = 0; i < len; i++){
      lists[i].checked = checked;
    }
  }
  _open() {
    const contentDom = this._element.querySelector('[data-body]');
    this.isActived = true;
    this._element.classList.add('is-active');
    if (contentDom === null) return;
    if (this.expandSize === null) {
      this._element.classList.add('is-expanded');
      this.expandSize = contentDom.offsetHeight;
      this._element.classList.remove('is-expanded');
    }
    contentDom.style.height = 0;
  }
  _getExtraSize(){
    // let extraSize = window.visualViewport.height;
    let extraSize = window.deviceInfo.isPC ?window.visualViewport.height:this.innerSize;
    
    if(document.querySelector('.ly-header')){
      extraSize -= document.querySelector('.ly-header').offsetHeight;
    }
    if(this._element.querySelector('.c-floating__header')){
      extraSize -= this._element.querySelector('.c-floating__header').offsetHeight;
    }
    if(this._element.querySelector('.c-floating__footer')){
      extraSize -= this._element.querySelector('.c-floating__footer').offsetHeight;
    }
    return extraSize;
  }
  _close() {
    this.expand(false);
    this.isActived = false;
    this._element.classList.remove('is-active');
  }
  open(){
    this._open()
  }
  close(){
    
    this._close()
  }
  expand(expandBoolean) {
    if(this.isActived === false) return;
    if(expandBoolean === true && this.isExpanded === false){
      this._expand(expandBoolean);
    }else if(expandBoolean === false && this.isExpanded === true){
      this._expand(expandBoolean);
    }
  }
  destroy(regain){
    this._removeEvents();
    super.destroy(regain);
  }
  _expand(boolean) {
    
    this.isExpanded = boolean;
    const contentDom = this._element.querySelector('[data-body]');
    if (contentDom === null) return;
    if (boolean) {
      this._element.classList.add('is-expanded');
      if(this.expandSize > this._getExtraSize()){
        contentDom.style.height = this._getExtraSize() + 'px';
      }else{
        contentDom.style.height = this.expandSize + 'px';
      }
      Dialog.OpenPopEvent(this._element);
      this._element.querySelector('.c-floating__body').scrollTop = 0;
    } else {
      this._element.classList.remove('is-expanded');
      contentDom.style.height = 0;
      // EventHandler.trigger(this._element, Dialog.EVENT.CLOSED, {
      //   component: this,
      // });
      Dialog.ClosePopEvent(this._element);
    }
  }
}
export default AgreementFloat;
