import UI from './ui-base';
import EventHandler from '../_dependencyvendor/event-handler';
import { dataSetToObject } from '../util/dom-util';

// eslint-disable-next-line no-unused-vars
const VERSION = '1.0.2';
const NAME = 'ui.accordion';

const IDENTIFIER = {
  HEADER: 'data-acc-header',
  OBSERVER: 'data-acc-observer',
};

const STATE = {
  EXPANDING: 'expanding',
  EXPAND: 'expand',
  OPEN: 'open',
};

const dataAttrConfig = {
  init: true,
  active: -1,
  activeClass: 'active',
  toggle: true,
  onlyOne: false,
  animation: true,
  initialClass: 'ui-accordion',
};

const defaultConfig = {
  ...dataAttrConfig,
  delegate: {
    selectFilter: (els, el, className) => {
      return els[0].closest(className) === el.closest(className);
    },
    pickContent: header => header.nextElementSibling,
    onAddClass: (state, current, activeClass) => {
      if (state === 'open') {
        current.header.classList.add(activeClass);
      } else {
        current.header.classList.remove(activeClass);
      }
    },
  },
  debug: false,
};

export default class Accordion extends UI {
  constructor(element, config = {}) {
    super(element, config);
    // return;
    this._isTransitioning = false;
    this._currentActiveIndex = -1;
    this._elements = {};
    this._currentInfo = null;
    this._beforeInfo = null;
    this._setupConfig(config);
    this._clearFunc = null;
    this._observer = null;
    this._observerElement = null;

    // modify 김원욱 - 고정 노출이 필요한 경우 체크
    this.dataTabtype = config.onlyOne === true && element.getAttribute('data-tab-type') !== null;

    if (this.dataTabtype) {
      const activeEle = element.querySelector('.c-accordion__button[aria-expanded="true"]');

      if (activeEle) {
        // const activeEle = element.querySelector('.c-accordion__button[aria-expanded="true"]');
        this.init();
        if (activeEle) {
          const controls = activeEle.getAttribute('aria-controls');
          const activeTarget = this._elements[controls];
          this._isTransitioning = false;
          this._open(activeTarget);
        }
      } else {
        this._config.active = this._config.active === -1 ? 0 : this._config.active;
        this.init();
      }
      return;
    }
    // 현재 UI가 즉시 초기화 가능 상태인지 체크 후 초기화 처리
    if (this._config.init === true || this._config.init === 'true') {
      // 현재 UI가 즉시 초기화 가능 상태인지 체크 후 초기화 처리
      this.init();
    }
  }

  static GLOBAL_CONFIG = {};

  static get EVENT() {
    return {
      OPEN: `${NAME}.open`,
      OPEND: `${NAME}.opened`,
      CLOSE: `${NAME}.close`,
      CLOSED: `${NAME}.closed`,
    };
  }

  static get NAME() {
    return NAME;
  }

  /**
   * 컴포넌트 초기화
   */
  init() {
    this.isInitialize = true;

    this._initVars();

    this._initEvents();

    this._initARIA();

    this.open(this._config.active);
    if (this._config.debug === true) {
      console.warn(`${Accordion.NAME}: initialized - [${this.id}]`);
    }
  }

  update() {
    this.destroy();
    this.init();
  }

  destroy(regain = true) {
    EventHandler.off(this._element, super._eventName('click'));
    if (this._config.debug === true) {
      console.warn(`${NAME}: destroyed - [${this._element}]`);
    }

    this._clearFunc();
    if (this._observer) {
      this._observer.disconnect();
      this._observer = null;
    }

    this._elements = {};
    this._currentInfo = null;
    this._beforeInfo = null;
    if (regain === false) {
      // 인스턴스 까지 모두..
      this._config = null;
      super.destroy();
    }
  }

  open(target) {
    if (!isNaN(target)) {
      const info = this._getHeaderByIndex(Number(target));
      if (info) {
        this._open(info);
      }
    } else {
      if (typeof target === 'string') {
        if (this._tabElements && this._elements[target]) {
          this._open(this._elements[target]);
        } else {
          console.warn(`${NAME}: [${target}] does not match any content element!`);
        }
      } else {
        const dName = target.getAttribute(IDENTIFIER.HEADER);
        if (dName && this._elements) {
          this._open(this._elements[dName]);
        }
      }
    }
  }

  close(target) {
    const { debug } = this._config;
    if (typeof target === 'number') {
      const info = this._getHeaderByIndex(target);
      if (info) {
        this._close(info);
      }
    } else if (typeof target === 'string') {
      if (this._tabElements && this._elements[target]) {
        this._close(this._elements[target]);
      } else if (debug === true) {
        console.warn(`${NAME}: [${target}] does not match any content element!`);
      }
    } else if (typeof target === 'object') {
      const dName = target.getAttribute(IDENTIFIER.HEADER);
      if (dName && this._elements) {
        this._close(this._elements[dName]);
      }
    }
  }
  _getButtonTarget(header) {
    if (header.nodeName === 'A' || header.nodeName === 'BUTTON') return header;
    else return header.querySelector('.c-accordion__button');
  }
  _createInfo() {
    const { selectFilter, pickContent } = this._config.delegate;
    const headers = this._element.querySelectorAll(`[${IDENTIFIER.HEADER}]`);
    const className = `.${this._config.initialClass}`;
    let index = 0;
    for (const header of headers) {
      if (selectFilter(headers, header, className)) {
        const button = this._getButtonTarget(header);
        let controls = header.getAttribute(IDENTIFIER.HEADER);

        if (controls === '') {
          header.setAttribute(IDENTIFIER.HEADER, `#${super._getRandomSerial()}`);
          controls = header.getAttribute(IDENTIFIER.HEADER);
          pickContent(header).setAttribute('id', controls.replace('#', ''));
        }
        const content = document.querySelector(controls);

        button.setAttribute('aria-controls', `#${content.getAttribute('id')}`);
        const expanded = button.getAttribute('aria-expanded') || false;
        button.setAttribute('aria-expanded', expanded);
        const labelledby = header.getAttribute('id') || super._getRandomSerial();
        button.setAttribute('id', labelledby);
        content.setAttribute('aria-labelledby', labelledby);
        // if(header.nodeName === 'a' || header.nodeName === 'button'){
        //   header.setAttribute('aria-expanded', expanded);
        // }

        // header.setAttribute('aria-expanded', expanded);

        if (!content) {
          super._throwError(Accordion.NAME, `[${controls}] does not match any content element!`);
        }
        this._elements[controls] = {
          header,
          content,
          index,
          labelledby,
          controls,
          expanded,
          isOpen: false,
        };
        index += 1;
      }
    }
    // clear function
    return () => {
      // 동적으로 추가되었던 속성을 모두 삭제해준다.
      for (const key in this._elements) {
        if (Object.prototype.hasOwnProperty.call(this._elements, key)) {
          const info = this._elements[key];
          if (info.content.getAttribute('id').indexOf('id_') > -1) {
            info.content.removeAttribute('id');
          }
          if (info.header.getAttribute('id').indexOf('id_') > -1) {
            info.header.removeAttribute('id');
          }
          if (info.header.getAttribute('data-acc-header').indexOf('id_') > -1) {
            info.header.setAttribute('data-acc-header', '');
          }
          info.header.removeAttribute('aria-expanded');
        }
      }
    };
  }

  /**
   * 변수 초기화
   */
  _initVars() {
    this._observerElement = this._element.querySelector(`[${IDENTIFIER.OBSERVER}]`);

    this._clearFunc = this._createInfo();
  }

  /**
   * 이벤트 초기화
   */
  _initEvents() {
    EventHandler.on(this._element, super._eventName('click'), this._headerClickHandler.bind(this));
    if (this._observerElement) {
      this._observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
          if (mutation.type === 'childList') {
            this.update();
          }
        });
      });
      this._observer.observe(this._observerElement, {
        childList: true,
      });
    }
  }

  _headerClickHandler(event) {
    if (!event.target.tagName.match(/^A$|AREA|INPUT|TEXTAREA|SELECT|BUTTON|LABEL/gim)) {
      event.preventDefault();
    }

    const { toggle } = this._config;
    const target = event.target.closest(`[data-acc-header]`);
    if (target) {
      const contentName = target.getAttribute(IDENTIFIER.HEADER);
      const currentInfo = this._elements[contentName];

      if (toggle === true) {
        if (currentInfo.isOpen === true) {
          if (this.dataTabtype === false) this._close(currentInfo);
        } else {
          this._open(currentInfo);
        }
      }
    }
  }

  _open(targetInfo) {
    this._expandedCheck(targetInfo, true);
    if (this._getIsTransition()) return;
    this._currentInfo = targetInfo;
    const {
      activeClass,
      delegate: { onAddClass },
      animation,
    } = this._config;
    const { content } = targetInfo;

    this._currentInfo.isOpen = true;
    onAddClass.apply(this, ['open', targetInfo, activeClass]);
    EventHandler.trigger(this._element, Accordion.EVENT.OPEN, {
      current: targetInfo,
    });
    if (animation === false) {
      // for ie11
      content.classList.add(STATE.EXPAND);
      content.classList.add(STATE.OPEN);
    } else {
      this._isTransitioning = true;
      content.classList.add(STATE.EXPANDING);
      content.classList.remove(STATE.EXPAND);
      content.style.height = `${content.scrollHeight}px`;
      EventHandler.one(content, 'transitionend', () => {
        content.classList.remove(STATE.EXPANDING);
        // for ie11
        content.classList.add(STATE.EXPAND);
        content.classList.add(STATE.OPEN);
        content.style.height = '';
        this._isTransitioning = false;
        EventHandler.trigger(this._element, Accordion.EVENT.OPEND, {
          current: targetInfo,
        });
      });
    }
    if (this._config.onlyOne !== false && this._beforeInfo) {
      if (this._beforeInfo !== this._currentInfo) {
        this._isTransitioning = false;
        this._close(this._beforeInfo);
      }
    }
    this._beforeInfo = targetInfo;
  }
  _expandedCheck(targetInfo, boolean) {
    const header = targetInfo.header;
    const content = targetInfo.content;
    this._getButtonTarget(header).setAttribute('aria-expanded', boolean);
    if (boolean) {
      content.setAttribute('tabindex', 0);
    } else {
      content.removeAttribute('tabindex');
    }
  }
  _getIsTransition() {
    return this._isTransitioning;
  }
  _close(targetInfo) {
    if (this._getIsTransition()) return;
    this._expandedCheck(targetInfo, false);
    this._currentInfo = targetInfo;
    const {
      activeClass,
      delegate: { onAddClass },
      animation,
    } = this._config;
    const { content } = targetInfo;
    this._currentInfo.isOpen = false;
    onAddClass.apply(this, ['close', targetInfo, activeClass]);
    EventHandler.trigger(this._element, Accordion.EVENT.CLOSE, {
      current: targetInfo,
    });
    if (animation === false) {
      content.classList.remove(STATE.OPEN);
    } else {
      this._isTransitioning = true;
      content.style.height = `${content.getBoundingClientRect().height}px`;
      content.heightCache = content.offsetHeight;
      content.style.height = ``;
      content.classList.add(STATE.EXPANDING);
      content.classList.remove(STATE.EXPAND);
      content.classList.remove(STATE.OPEN);
      EventHandler.one(content, 'transitionend', () => {
        content.classList.remove(STATE.EXPANDING);
        content.classList.add(STATE.EXPAND);
        this._isTransitioning = false;
        EventHandler.trigger(this._element, Accordion.EVENT.CLOSED, {
          current: targetInfo,
        });
      });
    }
  }

  openAll() {
    for (const key in this._elements) {
      if (Object.prototype.hasOwnProperty.call(this._elements, key)) {
        const info = this._elements[key];
        if (info.isOpen !== true) {
          this._open(info);
          this._isTransitioning = false;
        }
      }
    }
  }

  closeAll() {
    for (const key in this._elements) {
      if (Object.prototype.hasOwnProperty.call(this._elements, key)) {
        const info = this._elements[key];
        if (info.isOpen === true) {
          this._close(info);
          this._isTransitioning = false;
        }
      }
    }
  }

  /**
   * 접근성 관련 aria 속성 셋팅
   */
  _initARIA() {}

  _setupConfig(config) {
    this._config = {
      ...defaultConfig,
      ...Accordion.GLOBAL_CONFIG,
      ...config,
      ...dataSetToObject(this._element, dataAttrConfig, 'acc'),
    };
  }

  _getHeaderByIndex(index) {
    let r = null;
    for (const key in this._elements) {
      if (Object.prototype.hasOwnProperty.call(this._elements, key)) {
        const info = this._elements[key];
        const tabIndex = info.index;
        if (tabIndex === index) {
          r = info;
          break;
        }
      }
    }
    return r;
  }
}
