import UI from './ui-base';
import EventHandler from '../_dependencyvendor/event-handler';
import { dataSetToObject } from '../util/dom-util';

// eslint-disable-next-line no-unused-vars
const VERSION = '1.0.1';
const NAME = 'ui.tab';

const IDENTIFIER = {
  HEADER: 'data-tab-header',
  OBSERVER: 'data-tab-observer',
  SCROLL_TARGET: 'data-tab-scroller',
};

const dataAttrConfig = {
  init: true,
  activeClass: 'active',
  initialClass: 'ui-tab',
  active: 0,
  direction: 'horizontal',
};

const defaultConfig = {
  ...dataAttrConfig,
  delegate: {
    selectFilter: (els, el, className) => {
      return els[0].closest(className) === el.closest(className);
    },
    onAddClass: (state, current, activeClass) => {
      if (state === 'show') {
        current.header.classList.add(activeClass);
      } else {
        current.header.classList.remove(activeClass);
      }
    },
  },
  debug: false,
};

class Tab extends UI {
  constructor(element, config = {}) {
    super(element, config);
    this._scrollable = true;
    this._currentActiveIndex = -1;
    this._tabTotal = 0;
    this._tabElements = {};
    this._activeTabInfo = null;
    this._beforeTabInfo = null;
    this._setupConfog(config);
    this._clearFunc = null;
    this._observer = null;
    this._observerElement = null;
    // 현재 UI가 즉시 초기화 가능 상태인지 체크 후 초기화 처리
    if (this._config.init === true || this._config.init === 'true') {
      this.init();
    }
  }

  static GLOBAL_CONFIG = {};

  static get EVENT() {
    return {
      CHANGE: `${NAME}.change`,
    };
  }

  static get NAME() {
    return NAME;
  }

  /**
   * 컴포넌트 초기화
   */
  init() {
    this._initVars();
    this._initEvents();
    this._initARIA();
    this.show(Number(this._config.active));
    this._slidePropInit();
    let age = 0;
    if (this._config.debug === true) {
      console.warn(`${Tab.NAME}: initialized - [${this.id}]`);
    }
  }
  
  update() {
    this.destroy();
    this.init();
  }

  destroy(regain = true) {
    for (const key in this._tabElements) {
      if (Object.prototype.hasOwnProperty.call(this._tabElements, key)) {
        const { header } = this._tabElements[key];
        const { activeClass } = this._config;
        header.classList.remove(activeClass);
        EventHandler.off(header, this._eventName('keydown'));
        EventHandler.off(header, this._eventName('click'));
      }
    }
    this._clearFunc();
    if (this._observer) {
      this._observer.disconnect();
      this._observer = null;
    }
    if (this._config.debug === true) {
      console.warn(`${NAME}: destroyed - [${this._element}]`);
    }
    this._tabElements = {};
    this._activeTabInfo = null;
    this._beforeTabInfo = null;
    this._observerElement = null;
    if (regain === false) {
      // 인스턴스 까지 모두..
      this._config = null;
      super.destroy();
    }
  }

  /**
   * Tab Show
   * @param {number|string|object} target
   */
  show(target) {
    const { debug } = this._config;
    if (!isNaN(target)) {
      const info = this._getHeaderByIndex(target);
      if (info) {
        this._show(info);
      }
    } else {
      if (typeof target === 'string') {
        if (this._tabElements && this._tabElements[target]) {
          this._show(this._tabElements[target]);
        } else if (debug === true) {
          console.warn(`${Tab.NAME}: [${target}] does not match any content element!`);
        }
      } else {
        const dName = target.getAttribute(IDENTIFIER.HEADER);
        if (dName && this._tabElements) {
          this._show(this._tabElements[dName]);
        }
      }
    }
  }

  getCurrentIndex() {
    return this._currentActiveIndex;
  }

  getCurrentInfo() {
    return this._activeTabInfo;
  }

  _setupConfog(config) {
    this._config = {
      ...defaultConfig,
      ...Tab.GLOBAL_CONFIG,
      ...config,
      ...dataSetToObject(this._element, dataAttrConfig, 'tab'),
    };
  }

  _keyDownHandler(event) {
    const { direction } = this._config;
    switch (event.key) {
      case 'ArrowUp':
      case 'Up': {
        event.preventDefault();
        if (direction === 'horizontal') return;
        this._currentActiveIndex -= 1;
        if (this._currentActiveIndex < 0) {
          this._currentActiveIndex = this._tabTotal - 1;
        }
        break;
      }
      case 'ArrowDown':
      case 'Down': {
        event.preventDefault();
        if (direction === 'horizontal') return;
        this._currentActiveIndex += 1;
        if (this._currentActiveIndex > this._tabTotal - 1) {
          this._currentActiveIndex = 0;
        }
        break;
      }
      case 'ArrowRight':
      case 'Right': {
        event.preventDefault();
        if (direction === 'vertical') return;
        this._currentActiveIndex += 1;

        if (this._currentActiveIndex > this._tabTotal - 1) {
          this._currentActiveIndex = 0;
        }
        break;
      }
      case 'ArrowLeft':
      case 'Left': {
        event.preventDefault();
        if (direction === 'vertical') return;
        this._currentActiveIndex -= 1;
        if (this._currentActiveIndex < 0) {
          this._currentActiveIndex = this._tabTotal - 1;
        }
        break;
      }
      default: {
        // ...
      }
    }
    this.show(this._currentActiveIndex);
    for (const key in this._tabElements) {
      if (Object.prototype.hasOwnProperty.call(this._tabElements, key)) {
        const tab = this._tabElements[key];
        const { header, index } = tab;
        if (index === this._currentActiveIndex) {
          header.focus();
        }
      }
    }
  }

  _headerClickHandler(event) {
    event.preventDefault();
    const contentName = event.currentTarget.getAttribute(IDENTIFIER.HEADER);
    this._show(this._tabElements[contentName]);

    this._gifAnimateTarget(this._tabElements[contentName]);
  }

  _createTabInfo() {
    const { selectFilter } = this._config.delegate;
    const headers = this._element.querySelectorAll(`[${IDENTIFIER.HEADER}]`);
    const className = `.${this._config.initialClass}`;
    let index = 0;

    for (const header of headers) {
      if (selectFilter(headers, header, className)) {
        const controls = header.getAttribute(IDENTIFIER.HEADER);
        const content = document.querySelector(controls);
        const labelledby = header.getAttribute('id') || super._getRandomSerial();
        header.setAttribute('id', labelledby);
        if (!content) {
          super._throwError(`[${controls}] does not match any content element!`);
        }
        this._tabElements[controls] = {
          header,
          content,
          index,
          labelledby,
          controls,
        };
        index += 1;
      }
    }
    this._tabTotal = index;
    // clear function
    return () => {
      // 동적으로 추가되었던 속성을 모두 삭제해준다.
      for (const key in this._tabElements) {
        if (Object.prototype.hasOwnProperty.call(this._tabElements, key)) {
          const info = this._tabElements[key];
          if (info.content.getAttribute('id').indexOf('id_') > -1) {
            info.content.removeAttribute('id');
          }

          // if (info.content.getAttribute('aria-labelledby').indexOf('id_') > -1) {
          //   info.content.removeAttribute('aria-labelledby');
          // }
          if (info.header.getAttribute('id').indexOf('id_') > -1) {
            info.header.removeAttribute('id');
          }
        }
      }
    };
  }

  _initVars() {
    this._observerElement = this._element.querySelector(`[${IDENTIFIER.OBSERVER}]`);
    this._clearFunc = this._createTabInfo();
  }

  _initARIA() {
    const tablist = this._element.querySelector('[data-tab-list]');
    tablist.setAttribute('role', 'tablist');
    for (const key in this._tabElements) {
      if (Object.prototype.hasOwnProperty.call(this._tabElements, key)) {
        const tab = this._tabElements[key];
        tab.header.setAttribute('role', 'tab');
        tab.content.setAttribute('role', 'tabpanel');
        tab.header.setAttribute('aria-controls', tab.controls.replace('#', ''));
        tab.content.setAttribute('aria-labelledby', tab.header.getAttribute('id'));
        tab.header.setAttribute('aria-selected', 'false');
        tab.content.setAttribute('aria-hidden', 'false');
        tab.content.setAttribute('tabindex', '0');
        tab.content.style.display = 'none';
      }
    }
  }
  
  _initEvents() {
    for (const key in this._tabElements) {
      if (Object.prototype.hasOwnProperty.call(this._tabElements, key)) {
        const { header } = this._tabElements[key];
        EventHandler.on(header, this._eventName('keydown'), this._keyDownHandler.bind(this));
        EventHandler.on(header, this._eventName('click'), this._headerClickHandler.bind(this));
      }
    }
    if (this._observerElement) {
      this._observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
          if (mutation.type === 'childList') {
            this.update();
          }
        });
      });
      this._observer.observe(this._observerElement, {
        childList: true,
      });
    }
  }

  _show(tabInfo) {
    this._activeTabInfo = tabInfo;
    const { onAddClass } = this._config.delegate;
    const { content, index } = this._activeTabInfo;
    this._hideBefore();
    content.style.display = 'block';
    onAddClass.apply(this, ['show', this._activeTabInfo, this._config.activeClass]);
    EventHandler.trigger(this._element, Tab.EVENT.CHANGE, {
      current: this._activeTabInfo,
      before: this._beforeTabInfo,
    });
    
    this._currentActiveIndex = index;
    this._beforeTabInfo = tabInfo;
    this._ariaControl(tabInfo);
    const targetDom = this._element.getAttribute(IDENTIFIER.SCROLL_TARGET);
    
    const eventType = event === undefined?null:event.type
    
    if(targetDom !== null && eventType === 'click'){
      if(targetDom === 'window'){
        window.scrollTo({top:0, behavior:'instant'});
      }else{
        const target = this._findClassNameEle(this._element, targetDom);
        if(target !== null && target.scrollTop !== 0){
          target.scrollTop = 0;
        }
      }
      
    }
    else if(this._element.getAttribute('data-type-slide') !== null ) {
      const behavior = eventType === 'click' ? 'smooth':'instant';
      this.scrollTo(this._currentActiveIndex, behavior);
      
    }
  }
  paramShow(){
    
  }
  _findClassNameEle(ele, className)
  {
    let tmp = ele;
    while(tmp !== document.body){
      if(tmp.classList.contains(className)) return tmp;
      tmp = tmp.parentElement;
    }
    return null;
  }
  _hideBefore() {
    if (this._beforeTabInfo) {
      const { onAddClass } = this._config.delegate;
      const { content } = this._beforeTabInfo;
      if(this._element.getAttribute('data-type-slide') === null) content.style.display = 'none';
      onAddClass.apply(this, ['hide', this._beforeTabInfo, this._config.activeClass]);
    }
  }

  _ariaControl(tab) {
    for (const key in this._tabElements) {
      if (Object.prototype.hasOwnProperty.call(this._tabElements, key)) {
        const element = this._tabElements[key];
        if (tab.index === element.index) {
          tab.header.setAttribute('aria-selected', 'true');
          tab.header.setAttribute('tabindex', '0');
          tab.content.setAttribute('aria-hidden', 'false');
        } else {
          element.header.setAttribute('aria-selected', 'false');
          element.header.setAttribute('tabindex', '-1');
          element.content.setAttribute('aria-hidden', 'true');
        }
      }
    }
  }

  _getHeaderByIndex(index) {
    let r = null;
    for (const key in this._tabElements) {
      if (Object.prototype.hasOwnProperty.call(this._tabElements, key)) {
        const info = this._tabElements[key];
        const tabIndex = info.index;
        if (tabIndex === index) {
          r = info;
          break;
        }
      }
    }
    return r;
  }
  get _scrollToTarget(){
    const target = this._element;
    if (target.offsetHeight < target.scrollHeight) return target;
    else return window;
  }
  get _scrollTop(){
    const target = this._element;
    if (target.offsetHeight < target.scrollHeight) return target.scrollTop;
    else return window.scrollY;
  }
  _getIndexYValue(idx){
    const panels = document.querySelectorAll(".c-tabs--anchor-type .c-tabs__panel");
    let age = 0;
    for(let i = 0; i < idx; i++){
      age += panels[i].offsetHeight;
    }
    return age;
  }
  scrollTo(idx, behavior = 'instant'){
    const moveValue = this._getIndexYValue(idx);
    const onScroll = (e) =>{
      if(this._scrollTop === moveValue){
        this._scrollToTarget.removeEventListener('scroll', onScroll);
        this._scrollable = true;
      }
    };
    if(behavior !== 'instant') {
      this._scrollable = false;
      this._scrollToTarget.addEventListener('scroll', onScroll);
    }
    this._scrollToTarget.scrollTo({top:moveValue, behavior});
  }
  _slidePropInit(){
    const property = this._element.getAttribute('data-type-slide');
    const property2 = this._element.getAttribute('data-type-swiper');
    const me = this;
    if(property === null && property2 === null) return;
    document.querySelectorAll(".c-tabs--anchor-type .c-tabs__panel").forEach(function(panel, idx) {
      panel.style.display = "block";
      if(idx == 0) {
        panel.classList.add('is-active');
      }
    });
    if(property !== null) {
      const tabTypeAncs = document.querySelectorAll(".c-tabs--anchor-type");
      const tabBtns = document.querySelectorAll(".c-tabs--anchor-type .c-tabs__button");
      const panelSize = document.querySelector(".c-tabs--anchor-type .c-tabs__panel").offsetHeight;
      tabBtns.forEach(function(el, idx) {
        el.addEventListener("click", function(e) {
          const panels = document.querySelectorAll(".c-tabs--anchor-type .c-tabs__panel");
          // panels.forEach(function(panel) {
          //   panel.style.display = "block";
          // });
          me.scrollTo(idx, 'smooth');
          activeBtn(e.target);
        });
      });
      eventHandler();
    }
    if(property2 !== null) {
      const tabBtns = document.querySelectorAll(".c-tabs--anchor-type .c-tabs__button");
      tabBtns.forEach(function(el, idx) {
        el.addEventListener("click", function(e) {
          const panels = document.querySelectorAll(".c-tabs--anchor-type .c-tabs__panel");
          panels.forEach(function(panel) {
            panel.style.display = "block";
          });
          activeBtn(e.target);
        });
      });
    }
    
    function eventHandler(){
      me._scrollToTarget.addEventListener('scroll', scrollHandler);
    }
    function scrollHandler(e){
      if (me._scrollable === false) return;
      const tabBtns = document.querySelectorAll('.c-tabs--anchor-type .c-tabs__button');
      const panels = document.querySelectorAll(".c-tabs--anchor-type .c-tabs__panel");
      for(let pnl of panels){
        pnl.classList.remove('is-active');
      }
      const panel = me._element;
      const panelSize = panel.querySelector(".c-tabs__panel").offsetHeight;
      const prevActive = panel.querySelector(".c-tabs__panel.is-active");
      
     
      const idx = getCurrentIndex();
      
      if(prevActive !== panels[idx]){
        if(prevActive !== null) prevActive.classList.remove('is-active');
        panels[idx].classList.add('is-active');
        activeBtn(tabBtns[idx]);
      }
      
    }
    function getCurrentIndex(){
      const panel = me._element;
      const panels = document.querySelectorAll(".c-tabs--anchor-type .c-tabs__panel");
      const scrollValue = (panel.offsetHeight < panel.scrollHeight)?panel.scrollTop:window.scrollY;
      let age = 0;
      const len = panels.length;
      
      for(let i = 0; i < len; i++){
        
        age += panels[i].offsetHeight;
        if(scrollValue < age){
          return i;
        }
      }
      return null;
    }
    
    function activeBtn(target){
      const tabBtns = document.querySelectorAll(".c-tabs--anchor-type .c-tabs__button");
      tabBtns.forEach((mem, i) => {
        if(mem === target){
          mem.classList.add('is-active');
        }else{
          mem.classList.remove('is-active');
        }
      })
    }
  }

  _gifAnimateTarget(tab) {
    var animateTarget = document.querySelectorAll('[data-animate-target]');
    var activeTabAniTarget = tab.content.querySelectorAll('[data-animate-target]'); 
    var len = animateTarget.length;
    if(len === 0) return;
    animateTarget.forEach((mem)=>{
      _init(mem);
    });
    activeTabAniTarget.forEach((mem)=>{
      _activeTabChk(mem);
    });

    function _init(dom) {
      var originUrl = dom.getAttribute('data-target');
      dom.classList.remove('is-animated')
      dom.setAttribute('src', originUrl); 
    }

    function _activeTabChk(dom) {
      var rect = dom.getBoundingClientRect();
      var originUrl = dom.getAttribute('data-target');
      var animateUrl = dom.getAttribute('data-animate-target');
      if(rect.y + rect.height < window.innerHeight){
        dom.classList.add('is-animated')
        dom.setAttribute('src', animateUrl); 
      } else {
        dom.classList.remove('is-animated')
        dom.setAttribute('src', originUrl); 
      }
    }
  }
}
export default Tab;
