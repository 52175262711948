import Dialog from './dialog';
import { dataSetToObject } from '../util/dom-util';
import EventHandler from '../_dependencyvendor/event-handler';
// eslint-disable-next-line no-unused-vars
const VERSION = '1.0.0';
const NAME = 'bs-select';

const IDENTIFIER = {
  TRIGGER: 'data-dialog-trigger',
  CLOSE: 'data-dialog-close',
  FIRST_FOCUS: 'data-dialog-first',
  LIST: 'data-dialog-list',
  MULTI_TRIGGER: 'data-multi-trigger',
  MULTI_SELECT_CLASS: 'is-selected',
};
const dataAttrConfig = {
  bg: true,
  bgclose: true,
  destroy: false,
  animate: true,
  blur: true,
  openClass: 'show',
  closeClass: 'hide',
  defaultHtml: `<div role="dialog" class="c-modal c-modal--bs" tabindex="-1" style="z-index: 102;" ${IDENTIFIER.CLOSE}>
    <div role="document" class="c-modal__dialog">
      <div class="c-modal__content">
        <div class="c-modal__header">
          <h1 class="c-modal__title">{{data-title}}</h1>
        </div>
        <div class="c-modal__body" data-body>
        </div>
        <div class="c-modal__footer c-btn-wrap c-select-confirm">
          <button class="c-button c-button--lg c-button--primary" data-multi-trigger>확인</button>
        </div>
      </div>
    </div>
  </div>`,
  listHtml: `<ul>
              <li aria-label="{{index}} of {{length}}" class="bs-select-list" >
                <button aria-selected = "{{?true:}}" class="bs-select-list-btn-type1 {{?is-selected:}}">{{item}}</button>
              </li>
            </ul>`,
  btnHtml: `<div class="c-select c-select--bt"><button class="c-btn-select c-fl" ${IDENTIFIER.TRIGGER}>선택</button></div>`,
};

const defaultConfig = {
  ...dataAttrConfig,
  bgTemplate: `
    <div style="
      position: fixed; 
      width: 100%; 
      height: 100%;
      left: 0;
      top: 0;
      background-color: rgba(0,0,0,0.6);">
    </div>`,
  debug: false,
};

class BottomSheetSelect extends Dialog {
  constructor(form, config = {}) {
    super(form, config);
    this._form = form;
    this._default = null;
    this._bindData = null;    
    this._innerSize = window.visualViewport.height;
    // this.selectedIndex = 0;
    this._setupConfig(config);
    this._initVars();
    this.init();
    this._makeMarkUp();
    this._makeList();
    if (this._config.blur) this._addBlurMarkUp();
  }
  static get NAME() {
    return NAME;
  }
  static get EVENT() {
    return {
      SELECTED: `${NAME}.select`,
    };
  }
  _setupConfig(config) {
    this._config = {
      ...defaultConfig,
      ...BottomSheetSelect.GLOBAL_CONFIG,
      ...config,
      ...dataSetToObject(this._element, dataAttrConfig, 'acc'),
    };
  }
  init() {
    this._select = this._element.querySelector('select');
    
    this._multi = this._form.getAttribute('data-bs-select') === ''?false:true;
    const selected = this._select.querySelector('option[selected]');
    if (selected === null) this._select.selectedIndex = -1;
    this.selectedIndex = this._select.selectedIndex;
    this._selectSync();
    this._makeBtn();

    this._initEvents();
    if (this._config.debug === true) {
      console.warn(`${BottomSheetSelect.NAME}: initialized - [${this.id}]`);
    }
    this._directInputSet();
  }
  _selectSync(){
    if(this._multi){
      const selecteds = this._select.querySelectorAll('option');
      this._defaultData();
      const len = selecteds.length;
      for(let i = 0; i < len; i++){
        const dom = selecteds[i];
        if(dom.getAttribute('selected') !== null){
          this._data.indexs.push(i);
          this._data.values.push(dom.value);
          this._data.texts.push(dom.text);
        }
        
      }
    }
  }
  _getExtraSize(){
    // let extraSize = this._innerSize;
    let extraSize = window.deviceInfo.isPC ?window.visualViewport.height:this._innerSize;
      
    if(document.querySelector('.ly-header')){
      extraSize -= document.querySelector('.ly-header').offsetHeight;
    }else{
      extraSize -= 58;
    }
    this._element.querySelector('.c-modal__content').style['max-height'] = extraSize +'px'
    const modalElements = this._element.querySelector('.c-modal__content').children;
    const len = modalElements.length;
    for(let i = 0; i < len; i++){
      const element = modalElements[i];
      if(element.classList.contains('c-modal__body') === false){
        extraSize -= element.offsetHeight;
      }
    }
    return extraSize;
  }
  _makeBtn() {
    const me = this;
    const div = document.createElement('div');
    div.innerHTML = this._config.btnHtml;
    const btn = div.children[0];
    this._btn = btn;
    const trigger =
      btn.getAttribute(IDENTIFIER.TRIGGER) === null
        ? btn.querySelector(`[${IDENTIFIER.TRIGGER}]`)
        : btn;
    // btn.textContent = this._element.querySelector('select').value;
    trigger.setAttribute('aria-controls', this._id);
    
    // this._element.appendChild(btn);
    getChildOrder(btn);
    EventHandler.on(trigger, 'click', this._open.bind(this));
    this._select.style.display = 'none';
    let firstStr = null;
    if(this._select.getAttribute('data-default') !== null){
      trigger.innerHTML = this._select.getAttribute('data-default');
      firstStr= this._select.getAttribute('data-default');
    } else{
      firstStr= trigger.innerHTML;
    }
    if(this._multi && this._data.texts.length > 0){
      trigger.innerHTML = this._data.texts.join(',');
    }
    else if (this._select.selectedIndex >= 0) {
      this._changeSelectValue(this._select.selectedIndex);
    }
    const selectTmp = [];
    const len = this._select.options.length;
    for(let i = 0; i < len; i++){
      let mem = this._select.options[i];
      if(mem.getAttribute('selected') !== null) selectTmp.push(i);
    }
    
    this._default = {firstStr:firstStr, text:trigger.innerHTML, index:selectTmp};
    // this._default = {firstStr:firstStr, text:trigger.innerHTML, index:this._select.querySelectorAll('[selected]')}
    
    this._disabledCheck();
    // getChildOrder();
    function getChildOrder(btn){
      const parent = me._select.parentElement;
      const len = parent.children.length;
      for(let i = 0; i < len; i++){
        const child = parent.children[i];
        if(child === me._select){
          
          if(i === len - 1){
            parent.appendChild(btn);
          }else{
            parent.insertBefore(btn, parent.children[i+1]);
          }
          return;
        }
      }
    }
  }
  _disabledCheck() {
    if (this._element.getAttribute('disabled') !== null) {
      // this._btn.setAttribute('disabled', '');
      this._btn.querySelector('button').setAttribute('disabled', '');
      this._element.removeAttribute('disabled');
    }
  }
  _changeSelectValue(value) {
    let tmp = null;
    if (typeof value === 'string') {
    } else if (typeof value === 'number') {
      if (value >= 0) {
        tmp = this._select.options[value].textContent;
        this._select.selectedIndex = value;
        this._select.querySelectorAll('option[selected]').forEach((mem,idx)=>{
          mem.removeAttribute('selected');
        });

        this._select.options[value].setAttribute('selected','');
        this.selectedIndex = this._select.selectedIndex;
        
      }
    }
    const btn = document.querySelector(`[aria-controls="${this._id}"]`);
    if (tmp !== null) btn.textContent = tmp;
  }
  setValue(value){
    const btn = document.querySelector(`[aria-controls="${this._id}"]`);
    const len = this._select.options.length;
    let age = null;
    if(this._form.parentElement === null) return;
    if(value === undefined){
      btn.innerHTML = this._default.firstStr;
      this._select.selectedIndex = -1;
      this._data = null;
      for(let i = 0; i < len; i++){
        let mem = this._select.options[i];
        mem.removeAttribute('selected');
      }
    }else{
      
      const tmp = [];
      if(typeof value === 'number'){
        value = [value];
      }
      age = 0;
      for(let i = 0; i < len; i++){
        let mem = this._select.options[i];
        if(value.indexOf(i)>= 0){
          mem.setAttribute('selected', '');
          tmp.push(mem.innerHTML);
          age ++;
        }else{
          mem.removeAttribute('selected');
        }
        
      }
      // this._selectSync();
      btn.innerHTML = tmp.join(',');
      if(value.length === 1) this._select.selectedIndex = value[0];
      // this._select.selectedIndex = value;
    }
    this._makeList();
    // this.update();
    
  }
  firstSet(){
    const btn = document.querySelector(`[aria-controls="${this._id}"]`);
    const value = (this._default.index.length === 0)? undefined:this._default.index;
    this.setValue(value);
  }
  dispatchEvent(){
    EventHandler.trigger(this._form, BottomSheetSelect.EVENT.SELECTED, {
      component: this,
      data:this.getData(),
    });
  }
  _initVars() {
    this._texts = [];
    this._callBacker = null;
    this._bg = this._config.bg ? this._createBackground() : null;
    const container = this._element.querySelector('[comp-default]');
    const list = this._element.querySelector('[comp-list]');
    const btn = this._element.querySelector('[comp-btn]');
    if (container) this._config.defaultHtml = this._compParse(container);
    if (list) this._config.listHtml = this._compParse(list);
    if (btn) this._config.btnHtml = this._compParse(btn);
  }
  _compParse(value) {
    this._element.removeChild(value);
    return value.innerHTML;
  }
  _initEvents() {
    EventHandler.on(this._element, super._eventName('click'), this._mouseHandler.bind(this));
    EventHandler.on(this._select, 'change', this._changeHandler.bind(this));
    EventHandler.on(window, 'resize', this._resizeHandler.bind(this));
  }
  _resizeHandler(e){
    this._openUnderiOS15under();
    // console.log('resize');
  }
  _mouseHandler(e) {
    const target = e.target;
    const me = this;
    const btn = findParentNode(target, 'BUTTON');
    if (e.target.getAttribute(IDENTIFIER.CLOSE) !== null) {
      if(this._multi) me._dataRollBack();
      me._close(e);
    }
    else if (btn) {
      const custom = me._element.querySelector('[data-for-input]');
      let inputTarget = null;
      
      if(custom !== null  && custom.classList.contains(IDENTIFIER.MULTI_SELECT_CLASS)) {
        custom.classList.remove(IDENTIFIER.MULTI_SELECT_CLASS)
      }
      if(me._multi === false){
        // 단일 선택
        selectItem();
      }else{
        const finalSelects = me._element.querySelectorAll(`[${IDENTIFIER.MULTI_TRIGGER}]`);

        // 중복 선택
        if(btn.getAttribute(IDENTIFIER.MULTI_TRIGGER) !== null){
          
          me._setData();
          
          const selectBtn = document.querySelector(`[aria-controls="${this._id}"]`);
          selectBtn.innerHTML = this._data.texts.join(',');
          EventHandler.trigger(me._form, BottomSheetSelect.EVENT.SELECTED, {
            component: me,
            data:me.getData(),
          });
          me._close(e);
          
        }
        else if(btn.getAttribute('data-for-input') !== null){
          
          me._defaultData();
          const option = this._select.options[Number(btn.getAttribute('data-index'))]
          option.setAttribute('selected','');
          btn.classList.add(IDENTIFIER.MULTI_SELECT_CLASS);
         
          selectItem();
          me._setData();
        }
        else if(btn.classList.contains(IDENTIFIER.MULTI_SELECT_CLASS)){
          const option = this._select.options[Number(btn.getAttribute('data-index'))]
          option.removeAttribute('selected');
          btn.classList.remove(IDENTIFIER.MULTI_SELECT_CLASS);
        }else{
          const option = this._select.options[Number(btn.getAttribute('data-index'))]
          option.setAttribute('selected','');
          btn.classList.add(IDENTIFIER.MULTI_SELECT_CLASS)
        }
        me._activeFinalBtns(this._element.querySelectorAll(`.${IDENTIFIER.MULTI_SELECT_CLASS}`).length > 0);
        // this._makeList();
      }
      
    } 

    function selectItem(_data){
      const index = btn.getAttribute('data-index');
      if (index !== null) {
        me._changeSelectValue(Number(index));
      }
      me._makeList();
      me._close(e);
      EventHandler.trigger(me._form, BottomSheetSelect.EVENT.SELECTED, {
        component: me,
        data:me.getData(),
      });
    }
    
    function findParentNode(ele, value) {
      const values = value.split(',');
      let finder = null;
      values.forEach((mem, index) => {
        const key = mem.trim();

        const findObj = checkNode(ele, key);
        if (findObj) {
          finder = findObj;
          return;
        }
      });
      function checkNode(ele, value) {
        let tmp = ele;
        if (ele.nodeName === value) return ele;
        while (tmp.nodeName !== value) {
          tmp = tmp.parentElement;
          if (tmp === document.body) return null;
        }
        return tmp;
      }
      return finder;
    }
  }
  _directInputSet(){
    const custom = this._element.querySelector('[data-for-input]');
    const inputTarget = custom === null ? null: document.querySelector(custom.getAttribute('data-for-input'));
    if(inputTarget){
      if(custom && (custom.classList.contains(IDENTIFIER.MULTI_SELECT_CLASS) || custom.selected)){
        inputTarget.style.display = 'block';
      }else{
        inputTarget.style.display = 'none';
      }
    }
    
  }
  _activeFinalBtns(boolean){
    const finalSelects = this._element.querySelectorAll(`[${IDENTIFIER.MULTI_TRIGGER}]`);
    finalSelects.forEach((mem, idx)=>{
      if(boolean){
        mem.removeAttribute('disabled');
      }else{
        mem.setAttribute('disabled','');
      }
    })
  }
  _dataRollBack(){
    if(this._copyData === null) return;

    const len = this._select.options.length;
    let age = 0;
    for(let i = 0; i < len; i++){
      
      if(this._copyData.indexs.length > age && i === this._copyData.indexs[age]){
        this._select.options[i].setAttribute('selected','');
        age ++;
      }else{
        this._select.options[i].removeAttribute('selected');
      }
      const index = this._copyData.indexs[i];
    }
    this._makeList();
  }
  _changeHandler(e) {
    this._changeSelectValue(this._select.selectedIndex);
    if (this._isOpen) this._makeList();
  }
  getData() {
    if(this._multi){
      return this._data;
    } else{
      if(this._select.selectedIndex < 0) return null;
      const selectItem = this._element.querySelector(`[data-index="${this._select.selectedIndex}"]`);
      const rdata = {index:this._select.selectedIndex, value:selectItem.getAttribute('data-value'),text:selectItem.textContent}
      
      // if(selectItem === null) return null;
      return rdata
    } 
  }
  _open(e) {
    e.stopPropagation();
    super._open(e);
    this._openUnderiOS15under();
    this._scrollHandler();
    const selectIndex = this._select.selectedIndex;
    const focusTarget = this._element
      .querySelector('[data-body]')
      .querySelectorAll('button, a, input')[selectIndex];
    if (focusTarget) {
      if (focusTarget.getAttribute('type') === 'radio') {
        focusTarget.checked = true;
        this._element.querySelectorAll('input[type="radio"]').forEach((mem, i) => {
          if (mem === focusTarget) {
            mem.setAttribute('checked', 'checked');
          } else {
            mem.removeAttribute('checked');
          }
        });
      }
      focusTarget.focus();
    } else {
      // console.warn(this._select, `에 원소가 없습니다.`);
    }

    // 복수 선택 하나 이상 선택 되어지고, 직접 입력 선택 되지 않은 상황에만 확인 버튼 활성화
    const activeFinalBool =  this._element.querySelector('.is-selected[data-for-input]') ===  null &&
                            this._element.querySelectorAll(`.${IDENTIFIER.MULTI_SELECT_CLASS}`).length > 0
    this._activeFinalBtns(activeFinalBool);
    this._copyData = this.getData();
  }
  _openUnderiOS15under(){
    if(document.body.classList.contains('c-ios_under_15')){
      this._element.querySelector('.c-modal__body').style['max-height'] = this._getExtraSize() +'px';
    }
  }
  _close(e) {
    super. _close(e)
    this._directInputSet();  
  }
  update() {
    const selected = this._select.querySelector('option[selected]');
    if (selected === null) this._select.selectedIndex = -1;
    this.selectedIndex = this._select.selectedIndex;
    if (this._select.options.length > 0) this._changeSelectValue(this._select.selectedIndex);
    this._makeList();
    // if (this._isOpen) {
    //   this._makeList();
    // }
  }
  _defaultData(){
    this._data = {indexs:[], values:[], texts:[]};
    // const len = this._select.options.length;
    // for(let i = 0; i < len; i++){
    //   this._select.options[i].removeAttribute('selected');
    // }
  }
  _setData(){
    const selects = this._element.querySelectorAll(`.${IDENTIFIER.MULTI_SELECT_CLASS}`);
    this._defaultData();
    const len = selects.length;
    for(let i = 0; i < len; i++){
      const dom = selects[i];
      const idx = Number(dom.getAttribute('data-index'))
      this._data.indexs.push(idx);
      this._data.values.push(this._select.options[idx].value);
      this._data.texts.push(this._select.options[idx].text);
    }
  }
  _removeEvents() {
    EventHandler.off(this._element, super._eventName('click'));
  }
  bindData(map){
    this._bindData = map;
    this._resetMarkup();
    this._makeList();
  }
  _makeMarkUp() {
    const container = document.querySelector('.modal-container');
    const outer = document.createElement('div');
    outer.innerHTML = this._defaultParsing(this._config.defaultHtml);
    const sheet = outer.children[0];
    const etitle = sheet.querySelector('.c-modal__title');
    const ebody = sheet.querySelector('[data-body]');
    sheet.setAttribute('id', this._id);
    if (etitle) {
      etitle.setAttribute('id', `${this._id}-title`);
      sheet.setAttribute('aria-describedby', `#${etitle.getAttribute('id')}`);
    }
    ebody.setAttribute('id', `${this._id}-content`);
    sheet.setAttribute('aria-labelledby', `#${ebody.getAttribute('id')}`);
    container.appendChild(sheet);
    this._element = sheet;
  }
  _resetMarkup(){
    const container = document.querySelector('.modal-container');
    if(this._element !== null){
      container.removeChild(this._element);
      this._makeMarkUp();
    }
  }
  _makeList() {
    this._element.querySelector('[data-body]').innerHTML = ''; // 바디 비우기
    const len = this._select.options.length;
    const div = document.createElement('div');
    div.innerHTML = this._config.listHtml;
    const list = div.querySelector('ul, ol') ? div.children[0] : div;
    const comp = list.children[0];
    list.removeChild(comp);
    for (let i = 0; i < len; i++) {
      const li = this._listParsing(comp, i);
      const classes = li.getAttribute('data-classes');
      if (classes !== null) {
        classes.split(' ').forEach((mem, index) => {
          li.classList.add(mem);
        });
      }
      const btn = li.querySelector('button, a, input');
      btn.setAttribute('data-value', this._select.options[i].value);
      btn.setAttribute('data-index', i);
      if(this._select.options[i].getAttribute('data-for-input') !== null){
        btn.setAttribute('data-for-input', this._select.options[i].getAttribute('data-for-input'));
      }
      if(this._select.options[i].getAttribute('selected') !== null){
        btn.classList.add(IDENTIFIER.MULTI_SELECT_CLASS);
      }else{
        btn.classList.remove(IDENTIFIER.MULTI_SELECT_CLASS);
      }
      
      this._select.options[i].classList.forEach((mem, i) => {
        li.classList.add(mem);
      });
      // btn.setAttribute('class', this._select.options[i].getAttribute('class'));
      list.appendChild(li);
    }
    if (div.querySelector('ul, ol')) {
      this._element.querySelector('[data-body]').appendChild(list);
    } else {
      this._element.querySelector('[data-body]').innerHTML = list.innerHTML;
    }
    if(this._multi === false){
      const confirm = this._element.querySelector('.c-select-confirm');
      if(confirm !== null){
        const parent = confirm.parentElement;
        parent.removeChild(confirm);
      }
    }else {
      this._element.querySelector('.c-modal__body').classList.add('multi-select');
    }
  }
  _addBlurMarkUp() {
    const sheetBody = this._element.querySelector('.c-modal__body');
    if (sheetBody === null) return;
    EventHandler.on(sheetBody, 'scroll', this._scrollHandler.bind(this));
  }
  _scrollHandler(e) {
    const sheetBody = this._element.querySelector('.c-modal__body');
    if (sheetBody.scrollHeight <= sheetBody.offsetHeight) return;
    const maxScrollSize = sheetBody.scrollHeight - sheetBody.offsetHeight;
    if (sheetBody.scrollTop > 24) {
      sheetBody.classList.add('top-blur');
    } else {
      sheetBody.classList.remove('top-blur');
    }
    if (sheetBody.scrollTop > maxScrollSize - 30) {
      sheetBody.classList.remove('bottom-blur');
    } else {
      sheetBody.classList.add('bottom-blur');
    }
  }
  _defaultParsing(value) {
    let tmp = value;
    let start;
    let end;
    do {
      start = tmp.indexOf('{{');
      end = tmp.indexOf('}}');
      const key = tmp.substr(start + 2, end - (start + 2));
      if (key === '' || key === null) return tmp;
      key = key.trim().replaceAll(' ', '');
      const replacer = tmp.substr(start, end - start + 2);
      const dom = this._form.querySelector(`[${key}]`);
      if (this._bindData !== null && this._bindData[key] !== undefined){
        const substit = this._bindData[key];
        tmp = tmp.replace(replacer, substit);
      }
      else if (dom) {
        const substit = dom.getAttribute(key) ? dom.getAttribute(key) : dom.innerHTML;
        tmp = tmp.replace(replacer, substit);
        
      } else {
        tmp = tmp.replace(replacer, key);
      }
    } while (tmp.indexOf('{{') !== -1);
    return tmp;
  }
  _listParsing(comp, index) {
    let temp = comp.outerHTML;
    const div = document.createElement('div');
    const colum = temp.split(' ');

    for (let i = 0; i < colum.length; i++) {
      let sec = colum[i];
      let ii = 0;
      while (sec.indexOf('{{') >= 0) {
        const stn = sec.indexOf('{{');
        const endn = sec.indexOf('}}');
        const replacer = sec.substr(stn, endn - stn + 2);
        // console.log(replacer)
        replacer = replacer.trim();
        const value = replacer.substr(2, replacer.length - 4);
        if (value.substr(0, 1) === '?') {
          const arrs = value.substr(1).split(':');
          // const attr = this._select.selectedIndex === index ? arrs[0] : arrs[1];
          let attr = this._select.options[index].getAttribute('selected') !==null ? arrs[0] : arrs[1];
          
          if (attr == '') {
            sec = sec.replace(replacer, attr);
          } else {
            sec = sec.replace(replacer, attr);
          }
        } else if (value.trim() == 'index') {
          sec = sec.replace(replacer, index + 1);
        } else if (value.trim() == 'length') {
          sec = sec.replace(replacer, this._select.options.length);
        } else if (value.trim() == 'item') {
          sec = sec.replace(replacer, this._select.options[index].innerHTML);
        } else {
          // if (this._select.options[index].getAttribute(value.trim()))
          let attrValue = this._select.options[index].getAttribute(value.trim());
          if(attrValue === null) {
            const element = this._form.querySelector(`[${value.trim()}]`);
            if(element) attrValue = element.getAttribute(value.trim())?element.getAttribute(value.trim()):element.innerHTML;
          }

          if (attrValue) {
            sec = sec.replace(replacer, attrValue);
          } else {
            sec = sec.replace(replacer, '');
          }
        }
        if (++ii == 10) {
          return;
        }
      }
      if (sec.indexOf('=') >= 0 && sec.indexOf('""') >= 0) {
        const removes = sec.indexOf('""');
        sec = sec.substr(removes + 2);
      }
      colum[i] = sec;
    }
    temp = colum.join(' ');
    div.innerHTML = temp;
    return div.children[0];
  }
}

export { defaultConfig };
export default BottomSheetSelect;
