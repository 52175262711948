import EventHandler from '../_dependencyvendor/event-handler';
import Dialog from './dialog';
import smoothscroll from 'smoothscroll-polyfill';

// smoothscroll.polyfill();
class NavigateAllMenu {
  constructor() {
    // this.initialize();
    this._scrollable = true;
    // window.__forceSmoothScrollPolyfill__ = true;
  }
  static initialize() {
    const instance = NavigateAllMenu.getInstance();
    instance._eventSet();
  }
  static _instance = null;
  static getInstance() {
    if (NavigateAllMenu._instance === null) {
      NavigateAllMenu._instance = new NavigateAllMenu();
    }
    return this._instance;
  }
  _eventSet() {
    const openBtn = document.querySelector('.ly-header__gnb');
    const closeBtn = document.querySelector('.all-nav .c-btn-close');
    if (openBtn) {
      EventHandler.on(openBtn, 'click', this._openMenu);
    }

    if (closeBtn) {
      EventHandler.on(closeBtn, 'click', this._closeMenu);
    }
    this._scrollSpy();
  }
  _openMenu() {
    
    document.querySelector('.all-nav').style.display = 'block';
    document.querySelector('.nav-dim').style.display = 'block';
    document.querySelector('.ly-header').classList.add('is-active');
    EventHandler.trigger(document.querySelector('.all-nav'), Dialog.EVENT.OPEN, {
      component: NavigateAllMenu._instance,
    });
    setTimeout(() => {
      
      document.querySelector('.all-nav').classList.add('is-active');
      document.querySelector('.nav-dim').classList.add('is-active');
      document.body.style.overflow = 'hidden';
      EventHandler.trigger(document.querySelector('.all-nav'), Dialog.EVENT.OPENED, {
        component: NavigateAllMenu._instance,
      });
      document.querySelector('.all-nav').focus();
    }, 100);
    Dialog.OpenPopEvent(document.querySelector('.all-nav'));
  }
  _closeMenu() {
    document.querySelector('.all-nav').classList.remove('is-active');
    document.querySelector('.nav-dim').classList.remove('is-active');
    EventHandler.trigger(document.querySelector('.all-nav'), Dialog.EVENT.CLOSE, {
      component: NavigateAllMenu._instance,
    });
    setTimeout(() => {
      document.querySelector('.ly-header').classList.remove('is-active');
      document.querySelector('.all-nav').removeAttribute('style');
      document.querySelector('.nav-dim').removeAttribute('style');
      document.body.removeAttribute('style');
      EventHandler.trigger(document.querySelector('.all-nav'), Dialog.EVENT.CLOSED, {
        component: NavigateAllMenu._instance,
      });
    }, 500);
    const openBtn = document.querySelector('.ly-header__gnb');

    // console.log(openBtn);
    if (openBtn) {
      openBtn.focus();
    }
    
    
    Dialog.ClosePopEvent(document.querySelector('.all-nav'))
  }

  _scrollSpy() {
    const nav = document.querySelector('.all-nav__dep1');
    if (nav === null) return;
    const navBtns = nav.querySelectorAll('.dep1');
    const navCont = document.querySelector('.all-nav__sub-dep');
    const sects = navCont.querySelectorAll('section');
    const me = this;
    navBtns.forEach((el, idx) => {
      navBtns[0].classList.add('is-active');
      EventHandler.on(el, 'click', e => {
        e.preventDefault();
        // EventHandler.off(navCont, 'scroll');
        this._scrollable = false;
        const targetValue = sects[idx].offsetTop - 64;
        const onScroll = (e) =>{
          if(navCont.scrollTop === targetValue){
            navCont.removeEventListener('scroll', onScroll);
            me._scrollable = true;
          }
        };
        navCont.addEventListener('scroll', onScroll);
        navCont.scrollTo({top: targetValue, behavior: 'smooth' });
      
        me._activeMenu(el);
      });
    });
    EventHandler.on(navCont, 'touchstart', this._touchHandler.bind(this));
    EventHandler.on(navCont, 'touchend', this._touchHandler.bind(this));
    EventHandler.on(navCont, 'scroll', this._scrollHandler.bind(this));
  }
  _touchHandler(e){
    const navCont = document.querySelector('.all-nav__sub-dep');
  }
  _scrollHandler(e){
    const nav = document.querySelector('.all-nav__dep1');
    if (nav === null || this._scrollable === false) return;
    const navBtns = nav.querySelectorAll('.dep1');
    const navCont = document.querySelector('.all-nav__sub-dep');
    const sects = navCont.querySelectorAll('section');
    sects.forEach((el, idx) => {
      // sects[0].classList.add('is-active');
      if (Math.ceil(navCont.scrollTop) >= el.offsetTop - 64) {
        el.classList.add('is-active');
        this._activeMenu(navBtns[idx]);
      }
    });
  }
  scrollTo(target, behavior = 'instant'){
    if(target === null || target === undefined) return;
    else if(typeof target === 'number'){
      const moveActive = this._scrollToIndex(target);
    }
    else if(typeof target === 'string'){    
      const dom = document.querySelector(target);
      if(dom !== null) this._scrollToDom(dom);  
    }
    else if(typeof target === 'object'){
      this._scrollToDom(target, behavior);    
    }
  }
  scrollToPixel(value,  behavior = 'instant'){
    const navCont = document.querySelector('.all-nav__sub-dep');
    if(navCont === null) return;
    navCont.scrollTo({top: value, behavior});
  }
  _scrollToIndex(index, behavior = 'instant'){
    const lists = document.querySelectorAll('.all-nav__sub-dep li');
    const navBody = document.querySelector('.all-nav__body');
    const navCont = document.querySelector('.all-nav__sub-dep');
    const navBodyY = navBody.getBoundingClientRect().top;
    if(lists.length === 0 || index > lists.length - 1) return;
    const item = lists[index];
    const moveSize = item.offsetTop - navBodyY;
    this.scrollToPixel(moveSize, behavior);
    // navCont.scrollTo({top: moveSize, behavior});
  }
  _scrollToDom(dom, behavior = 'instant'){
    const navBody = document.querySelector('.all-nav__body');
    const navCont = document.querySelector('.all-nav__sub-dep');
    const navBodyY = navBody.getBoundingClientRect().top;
    if(navCont === null) return;
    const item = dom;
    const moveSize = item.offsetTop - navBodyY;
    // navCont.scrollTo({top: moveSize, behavior});
    this.scrollToPixel(moveSize, behavior);
  }
  _activeMenu(active) {
    const nav = document.querySelector('.all-nav__dep1');
    if (nav === null) return;
    const navBtns = nav.querySelectorAll('.dep1');
    navBtns.forEach((el, idx) => {
      if (active === el) {
        el.classList.add('is-active');
      } else {
        el.classList.remove('is-active');
      }
    });
  }
}
export default NavigateAllMenu;
