import { toHTML } from '../util/dom-util';

// eslint-disable-next-line no-unused-vars
const VERSION = '1.0.0';
const NAME = 'ui.loading-spinner';

class LoadingSpinner {
  constructor() {
    this._showCount = 0;
    this._template = toHTML(`
    <div class="c-loader__wrap">
      <div class="c-loader c-loader--circle">
        <svg class="spinner" xmlns="htttp://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
          <circle class="inner" cx="30" cy="30" r="25" fill="none" stroke-width="4" stroke="#d6d8da" />
          <circle class="outer" cx="30" cy="30" r="25" fill="none" stroke-width="4" stroke="#d31145" />
        </svg>
        <p>Loading...</p>
      </div>
    </div>
    `);
  }

  static get NAME() {
    return NAME;
  }

  show() {
    if (this._showCount > 0) return;
    document.body.appendChild(this._template);
    this._showCount++;
    AIA.Dialog.OpenPopEvent(this._template);
  }

  hide() {
    this._showCount--;
    if (this._showCount < 1) {
      document.body.removeChild(this._template);
    }
    AIA.Dialog.ClosePopEvent(this._template)
  }
}

export default new LoadingSpinner();
