import { toHTML } from '../util/dom-util';
import EventHandler from '../_dependencyvendor/event-handler';

// eslint-disable-next-line no-unused-vars
const VERSION = '1.0.0';
const NAME = 'ui.toast';

const defaultConfig = {
  templateContainer: ``,
  templateBox: ``,
  lifeTime: 2000,
};

class Toast {
  constructor(config = {}) {
    this._config = {
      ...defaultConfig,
      ...Toast.GLOBAL_CONFIG,
      ...config,
    };
    this._toastBox = null;

    if (Toast.INITAIL === null) {
      this._eventSet();
      Toast.INITAIL = true;
    }
  }

  static GLOBAL_CONFIG = {};
  static TOAST_HOLDER = null;
  static TOAST_HOLDER_CASE2 = null;
  static TOAST_COUNT = 0;
  static TOAST_COUNT_CASE2 = 0;
  static INTERVAL_ID = null;
  static INITAIL = null;

  static getContainer(template, typeCase) {
    if (typeCase === 'type2') {
      if (Toast.TOAST_HOLDER_CASE2 === null) {
        Toast.TOAST_HOLDER_CASE2 = toHTML(template);
        document.body.appendChild(Toast.TOAST_HOLDER_CASE2);
      }
    } else {
      if (Toast.TOAST_HOLDER === null) {
        Toast.TOAST_HOLDER = toHTML(template);
        document.body.appendChild(Toast.TOAST_HOLDER);
      }
    }
    return typeCase === 'type2' ? Toast.TOAST_HOLDER_CASE2 : Toast.TOAST_HOLDER;
  }

  static get NAME() {
    return NAME;
  }
  _eventSet() {
    if (window.deviceInfo.isiOS) {
      document.body.addEventListener('focus', this._foucusHandleriOS.bind(this), true);
      document.body.addEventListener('blur', this._foucusHandleriOS.bind(this), true);
    } else {
      document.body.addEventListener('focus', this._foucusHandlerAOS.bind(this), true);
      document.body.addEventListener('blur', this._foucusHandlerAOS.bind(this), true);
    }
  }
  _foucusHandleriOS(e) {
    const target = e.target;
    if (target.nodeName !== 'INPUT' || target.getAttribute('type') !== 'text') return;
    if (e.type === 'focus') {
      const me = this;
      if (Toast.INTERVAL_ID !== null) clearInterval(this._intervalId);
      Toast.INTERVAL_ID = setInterval(() => {
        me._scrollHandler();
      }, 30);

      window.addEventListener('scroll', this._scrollHandler);
    } else {
      clearInterval(Toast.INTERVAL_ID);
      Toast.INTERVAL_ID = null;
      this._clearFixed();
      window.removeEventListener('scroll', this._scrollHandler);
    }
  }
  _scrollHandler(e) {
    let tmp;
    if (Toast.TOAST_HOLDER !== null) {
      tmp = Toast.TOAST_HOLDER;
      const rect = tmp.getBoundingClientRect();

      const valid = Math.floor(tmp.offsetTop - rect.y);
      if (valid > 0) {
        tmp.style['margin-top'] = valid + 'px';
      }
    }
    if (Toast.TOAST_HOLDER_CASE2 !== null) {
      tmp = Toast.TOAST_HOLDER_CASE2;
      const rect = tmp.children[0].getBoundingClientRect();
      this.max = Math.max(this.max, window.scrollY);
      this.min = Math.min(this.max, window.scrollY);
      const valid = Math.floor(tmp.offsetTop - rect.y);
      const bSize = window.visualViewport.height;
      const inputBtn = document.querySelector('.follow-input-btn');
      if (inputBtn === null) {
        tmp.style.top = `calc(${valid}px + ${bSize}px - ${
          rect.height * tmp.children.length + 10
        }px )`;
      } else {
        tmp.style.top = `calc(${valid}px + ${bSize}px - ${
          rect.height * tmp.children.length + 10
        }px - ${inputBtn.offsetHeight}px)`;
      }
    }
  }
  _clearFixed() {
    let tmp;
    if (Toast.TOAST_HOLDER !== null) {
      tmp = Toast.TOAST_HOLDER;
      tmp.style.removeProperty('position');
      tmp.style.removeProperty('margin-top');
      tmp.style.removeProperty('top');
      tmp.style.removeProperty('transform');
    }
    if (Toast.TOAST_HOLDER_CASE2 !== null) {
      tmp = Toast.TOAST_HOLDER_CASE2;
      tmp.style.removeProperty('position');
      tmp.style.removeProperty('top');
      tmp.style.removeProperty('bottom');
      tmp.style.removeProperty('margin-bottom');
      tmp.style.removeProperty('transform');
    }
  }
  _foucusHandlerAOS(e) {
    if (e.type === 'focus') {
      this._androidFocus();
    } else {
      this._clearFixed();
    }
  }
  _androidFocus() {
    let tmp;
    const target = document.activeElement;
    if (target.nodeName !== 'INPUT' || target.getAttribute('type') !== 'text') return;

    if (Toast.TOAST_HOLDER_CASE2 !== null) {
      tmp = Toast.TOAST_HOLDER_CASE2;
      console.log(tmp);
      const inputBtn = document.querySelector('.follow-input-btn');
      if (inputBtn === null) {
        tmp.style.bottom = '10px';
      } else {
        tmp.style.bottom = `calc( 10px + ${inputBtn.offsetHeight}px)`;
      }
    }
  }
  show(message, type) {
    this._show(message, type);
    if (!window.deviceInfo.isiOS) {
      this._androidFocus();
    }
  }

  _show(message, type) {
    const _type = type || 'type1';
    this._toastBox = toHTML(this._config.templateBox.replace('{{MESSAGE}}', message));
    Toast.getContainer(this._config.templateContainer, _type).appendChild(this._toastBox);
    // eslint-disable-next-line no-unused-vars
    const reflow = this._toastBox.offsetHeight;
    if (_type == null) {
      Toast.getContainer(this._config.templateContainer, _type).classList.add('c-toast--type1');
    } else {
      Toast.getContainer(this._config.templateContainer, _type).classList.add('c-toast--' + _type);
    }
    this._toastBox.classList.add('is-active');
    if (_type === 'type2') Toast.TOAST_COUNT_CASE2++;
    else {
      Toast.TOAST_COUNT++;
    }

    setTimeout(() => {
      this._hide(_type);
    }, this._config.lifeTime);
  }

  _hide(type) {
    this._toastBox.classList.remove('is-active');
    this._toastBox.classList.add('is-deactive');
    EventHandler.one(this._toastBox, 'animationend', () => {
      const container = Toast.getContainer(this._config.templateContainer, type);
      container.removeChild(this._toastBox);
      if (type === 'type2') {
        Toast.TOAST_COUNT_CASE2--;
        if (Toast.TOAST_COUNT_CASE2 <= 0) {
          document.body.removeChild(Toast.TOAST_HOLDER_CASE2);
          Toast.TOAST_HOLDER_CASE2 = null;
        }
      } else {
        Toast.TOAST_COUNT--;
        if (Toast.TOAST_COUNT <= 0) {
          document.body.removeChild(Toast.TOAST_HOLDER);
          Toast.TOAST_HOLDER = null;
        }
      }
    });
  }
  
  showCustom1(message, container, delay){
    const markup = `<div role="tooltip" class="c-tooltip-box c-tooltip-box--type3">
      <div class="c-tooltip-box__content"> 
      ${message}
      </div>
    </div>`
    const div = document.createElement('div');
    div.innerHTML = markup;
    const tooltip = div.children[0];
    container.appendChild(tooltip); 

    tooltip.classList.add('is-active');
    if(container.querySelector('i')!== null){
      const hv = container.offsetHeight;
      tooltip.style.top = (hv + 10)+'px';
      tooltip.style.right = (hv / 2 - 30)+'px';
    }
    var _delay = delay === undefined ? 4000:delay;
      
    setTimeout(function () {
      tooltip.classList.add('is-deactive');
      tooltip.classList.remove('is-active');
      AIA.EventHandler.one(tooltip, 'animationend', function(){
        tooltip.classList.remove('is-deactive');
      });
    }, _delay)
  }
  showCustom2(message, container, delay){
    const markup = `<div role="tooltip" class="c-tooltip-box c-tooltip-box--type2">
      <div class="c-tooltip-arrow"></div>
      <div class="c-tooltip-box__content"> 
      ${message}
      </div>
      <button class="c-tooltip-box__close" data-tpbox-close>
        <span class="c-hidden">툴팁닫기</span>
      </button>
    </div>`
    const div = document.createElement('div');
    div.innerHTML = markup;
    const tooltip = div.children[0];
    // container.appendChild(tooltip); 
    container.prepend(tooltip);

    tooltip.classList.add('is-active');
    if(container.querySelector('i')!== null){
      const hv = container.offsetHeight;
      console.log('hv', hv);
      tooltip.style.top = (hv + 10)+'px';
      tooltip.querySelector('.c-tooltip-arrow').style.right = (hv / 2)+'px';
    }
    var _delay = delay === undefined ? 4000:delay;

    AIA.EventHandler.one( tooltip.querySelector('.c-tooltip-box__close'), 'click', function(){
      tooltip.classList.add('is-deactive');
      tooltip.classList.remove('is-active');
      AIA.EventHandler.one(tooltip, 'animationend', function(){
        tooltip.classList.remove('is-deactive');
      });
    });
  }
}

export default Toast;
