import UI from './ui-base';
import EventHandler from '../_dependencyvendor/event-handler';
import { dataSetToObject } from '../util/dom-util';

// eslint-disable-next-line no-unused-vars
const VERSION = '1.0.0';
const NAME = 'ui.inputcomponent';

const dataAttrConfig = {
  init: true,
  autoCheck: false,
  disabled: false,
};

const defaultConfig = {
  ...dataAttrConfig,
  debug: false,
};

export default class InputComponent extends UI {
  static GLOBAL_CONFIG = {};

  static get EVENT() {
    return {
      UNIT_COMPLETE: 'unitComplete',
      TOTAL_COMPLETE: 'totalComplete',
    };
  }

  static get NAME() {
    return NAME;
  }
  constructor(element, config = {}) {
    super(element, config);
    this._focusTarget = null;
    this._unitDictionary = null;

    this._setupConfig(config);
    this._attributeConfig();

    if (element.nodeName !== 'INPUT' && element.querySelector('input') === null) {
      console.log(element);
      throw new Error('요소에 input 엘리먼트가 없습니다.');
      return;
    }

    // const prevInstance = InputComponent.getInstance(element);

    this.init();
  }
  _setupConfig(config) {
    this._config = {
      ...defaultConfig,
      ...InputComponent.GLOBAL_CONFIG,
      ...config,
      ...dataSetToObject(this._element, dataAttrConfig, 'acc'),
    };
  }
  _attributeConfig() {
    if (this._element.getAttribute('data-auto') !== null) {
      this._config.autoCheck = true;
    }
    if (this._element.getAttribute('disabled') !== null) {
      this._config.disabled = true;
    }
  }
  static globalInit() {
    const compInputs = document.querySelectorAll('[data-input-com]');
    const len = compInputs.length;
    for (let i = 0; i < len; i++) {
      const mem = compInputs[i];
      new InputComponent(mem);
    }
  }
  /**
   * 컴포넌트 초기화
   */
  init() {
    this._data = {};
    this._initVars();

    this._initEvents();

    this._initARIA();

    if (this._config.debug === true) {
      console.warn(`${InputComponent.NAME}: initialized - [${this.id}]`);
    }
  }

  _initVars() {
    this._texts = [];
    this._callBacker = null;
    this._inputIndexCheck();
    this._element.setAttribute('data-focus', '');
  }
  _inputIndexCheck() {
    const inputs = this._element.querySelectorAll('input');
    const len = inputs.length;
    for (let i = 0; i < len; i++) {
      inputs[i].index = i;
    }
  }
  _initEvents() {
    EventHandler.on(this._element, 'DOMSubtreeModified', this._clickHandler.bind(this));
    EventHandler.on(this._element, super._eventName('click'), this._clickHandler.bind(this));
    EventHandler.on(this._element, super._eventName('input'), this._inputHandler.bind(this));
    EventHandler.on(this._element, super._eventName('keydown'), this._backspaceHandler.bind(this));

    this._attrChangeHandler();
  }
  _initARIA() {}
  _attrChangeHandler(e) {
    const me = this;
    const allinputDisable = boolean => {
      const inputs = me._element.querySelectorAll('input');
      const len = inputs.length;
      for (let i = 0; i < len; i++) {
        if (boolean) {
          inputs[i].setAttribute('disabled', '');
        } else {
          inputs[i].removeAttribute('disabled');
        }
      }
    };
    if (
      me._element.getAttribute('disabled') === null ||
      me._element.getAttribute('disabled') === false
    ) {
      allinputDisable(false);
    } else {
      allinputDisable(true);
    }
  }
  _clickHandler(e) {
    const firstItem = this._element.querySelectorAll('input')[0];
    const forEl = e.target.getAttribute('data-input-for');
    if (forEl !== null) {
      this._element.querySelector(forEl).focus();
      return;
    }
    if (e.target.nodeName === 'INPUT' || e.target.getAttribute('for') !== null) return;
    firstItem.focus();
  }
  _inputHandler(e) {
    // if (e.target.getAttribute('type') === 'password') {
    //   e.preventDefault();
    //   return;
    // }
    const inputs = this._element.querySelectorAll('input');
    const len = inputs.length;
    const input = e.target;
    this._inputTextDistribute(input, this._config.autoCheck);

    const item = [];
    for (let i = 0; i < len; i++) {
      const unitLen = Number(inputs[i].getAttribute('data-max-input-length'));
      item.push(inputs[i].value);
      if (unitLen === inputs[i].value.length) {
        this._implementEVent(inputs[i], InputComponent.EVENT.UNIT_COMPLETE);
      } else {
        if (this._unitDictionary) this._unitDictionary[inputs[i].index] = false;
      }
    }
    this._texts = item.concat();
    if (this._focusTarget !== null && document.activeElement !== this._focusTarget) {
      this._focusTarget.focus();
    }
    this._focusTarget = null;
    if (this._inputLenCompleteCheck()) {
      this._implementEVent(this._element, InputComponent.EVENT.TOTAL_COMPLETE);
    } else {
      if (this._unitDictionary) this._unitDictionary[InputComponent.EVENT.TOTAL_COMPLETE] = false;
    }
  }
  _backspaceHandler(e) {
    // if (e.target.getAttribute('type') === 'password') {
    //   if(e.keyCode === 9 || e.keyCode === 16) return;
    //   e.preventDefault();
    // } else
    if (e.keyCode === 8) {
      const input = e.target;

      if (this._config.autoCheck === false) return;

      if (input.nodeName !== 'INPUT') return;

      if (input.selectionStart === 0 && input.selectionEnd === 0) {
        const index = e.target.index - 1;
        const prevItem = this._element.querySelectorAll('input')[index];
        if (prevItem === undefined) return;
        prevItem.focus();
      }
    }
  }
  _inputTextDistribute(input, autoCheck) {
    const inputMax = Number(input.getAttribute('data-max-input-length'));
    const inputs = this._element.querySelectorAll('input');
    if (inputMax !== 0) {
      if (input.value.length > inputMax) {
        const textValue = input.value;
        const acceptValue = textValue.substr(0, inputMax);
        const addValue = textValue.substr(inputMax);
        input.value = acceptValue;
        if (autoCheck === false) return;
        const nextInput = inputs[input.index + 1];
        if (nextInput !== undefined) {
          if (nextInput.value === '') {
            nextInput.value = addValue;
            this._inputTextDistribute(nextInput);
          } else {
            this._focusTarget = nextInput;
          }
        } else {
          this._focusTarget = input;
        }
      } else {
        this._focusTarget = input;
      }
    }
  }
  _inputLenCompleteCheck() {
    let inputs = this._element.querySelectorAll('input');
    if (this._element.nodeName === 'INPUT') inputs = [this._element];
    const len = inputs.length;
    let age = 0;
    for (let i = 0; i < len; i++) {
      const input = inputs[i];
      const textLn = Number(input.getAttribute('data-max-input-length'));
      if (textLn === 0) return false;
      if (textLn === input.value.length) age++;
    }
    return age === len;
  }
  registCallBack(type, handler) {
    if (this._callBacker === null) this._callBacker = {};
    this._callBacker[type] = handler;
  }
  _implementEVent(element, type) {
    const index = element.index;
    if (this._unitDictionary === null) {
      this._unitDictionary = {};
    }
    const key = type === InputComponent.EVENT.UNIT_COMPLETE ? index : type;

    if (this._unitDictionary[key] === true) return;
    this._unitDictionary[key] = true;

    if (this._callBacker !== null && this._callBacker !== undefined) {
      if (type === InputComponent.EVENT.UNIT_COMPLETE) {
        const unitCallBack = this._callBacker[type];
        if (unitCallBack === undefined) return;
        unitCallBack(index);
      } else {
        const total = this._callBacker[type];
        if (total === undefined) return;
        total();
      }
    }
    EventHandler.trigger(element, type, {
      current: element,
      component: this,
    });
  }
  nextInput(input) {
    const mems = this._element.querySelectorAll('input[type="text"]');
    const len = mems.length;
    for (let i = 0; i < len; i++) {
      const mem = mems[i];
      if (mem === input) {
        return i + 1 < len ? mems[i + 1] : null;
      }
    }
    return null;
  }
  getTextArray() {
    return this._texts.concat();
  }
  getText() {
    return this._texts.join('');
  }

  update() {
    this.destroy();
    this.init();
  }
  destroy() {
    EventHandler.off(this._element, 'DOMSubtreeModified');
    EventHandler.off(this._element, super._eventName('click'));
    EventHandler.off(this._element, super._eventName('input'));
    EventHandler.off(this._element, super._eventName('keydown'));
  }
}
