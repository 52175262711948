import { toHTML, getElement, dataSetToObject, isVisible } from '../util/dom-util';
import EventHandler from '../_dependencyvendor/event-handler';
import UI from './ui-base';

// eslint-disable-next-line no-unused-vars
const VERSION = '1.0.1';
const NAME = 'ui.dialog';

const IDENTIFIER = {
  TRIGGER: 'data-dialog-trigger',
  CLOSE: 'data-dialog-close',
  FIRST_FOCUS: 'data-dialog-first',
};

const dataAttrConfig = {
  bg: true,
  zIndexIgnore: false,
  bgclose: true,
  destroy: false,
  animate: true,
  banFocus: false,
  openClass: 'show',
  closeClass: 'hide',
};

const detachFocusTarget = 'input, button, a, textarea, select';

const defaultConfig = {
  ...dataAttrConfig,
  bgTemplate: `
    <div style="
      position: fixed; 
      width: 100%; 
      height: 100%;
      left: 0;
      top: 0;
      background-color: rgba(0,0,0,0.6);">
    </div>`,
};

const ZINDEX = {
  CONTENT: 131,
  BACKDROP: 130,
  INCREASE: 1,
};

class Dialog extends UI {
  constructor(element, config = {}) {
    super(element, config);
    this._setupConfog(config);
    this._bg = null;
    this._closeButtons = null;
    this._openButton = null;
    this._isOpen = false;

    this._init();
  }

  static get EVENT() {
    return {
      OPEN: `${NAME}.open`,
      OPENED: `${NAME}.opened`,
      CLOSE: `${NAME}.close`,
      CLOSED: `${NAME}.closed`,
      FIRST_OPEN: `${NAME}.firstOpen`,
      LAST_CLOSE: `${NAME}.laseClose`,
    };
  }

  /**
   * 글로벌 설정은 처음에..
   */
  static GLOBAL_CONFIG = {};
  static COUNT = 0;
  static SCROLL_POY = 0;
  static STACK_POP_ID = [];
  /**
   * 현재 활성화 되어있는 dialog 모두 닫기
   */
  static closeAll() {
    const instances = Dialog.getInstances();
    for (const p in instances) {
      if (Object.prototype.hasOwnProperty.call(instances, p)) {
        const dialog = instances[p];
        if (dialog) dialog.close();
      }
    }
  }

  static get NAME() {
    return NAME;
  }

  static OpenPopEvent(dom, ariaHidden) {
    if (Dialog.COUNT === 0) {
      EventHandler.trigger(window, Dialog.EVENT.FIRST_OPEN);
    }
    Dialog.COUNT++;
    if (dom === undefined) return;

    if (dom.id !== '') {
      Dialog.STACK_POP_ID.push(dom.id);
    } else {
      Dialog.STACK_POP_ID.push(dom);
    }
    if (ariaHidden !== false) Dialog.ariaHiddenSet();

    var tabs = dom.querySelectorAll('.c-tabs');
    if (tabs.length == 0) return;
    var activeTarget = dom.querySelectorAll('.c-tabs .c-tabs__button')[0];
    AIA.EventHandler.trigger(activeTarget, 'click');
  }
  static ClosePopEvent(dom, ariaHidden) {
    Dialog.COUNT--;
    if (Dialog.COUNT <= 0) {
      Dialog.COUNT = 0;
      EventHandler.trigger(window, Dialog.EVENT.LAST_CLOSE);
    }
    if (dom === undefined) return;
    let ele;
    if (dom.id !== '') {
      ele = dom.id;
    } else {
      ele = dom;
    }
    const idx = Dialog.STACK_POP_ID.indexOf(ele);
    if (idx >= 0) Dialog.STACK_POP_ID.splice(idx, 1);
    if (ariaHidden !== false) Dialog.ariaHiddenSet();
  }

  static ariaHiddenSet() {
    const active = Dialog.STACK_POP_ID.length > 0;
    const activeEle = active === false ? null : Dialog.STACK_POP_ID[Dialog.STACK_POP_ID.length - 1];
    const dom = typeof activeEle === 'string' ? document.getElementById(activeEle) : activeEle;

    const modalActive = () => {
      const bodyContainer = document.querySelectorAll('body > *:not(script)');
      const len = bodyContainer.length;
      for (let i = 0; i < len; i++) {
        const ele = bodyContainer[i];
        const modal = ele.classList.contains('modal-container');
        const tempBool = modal ? !active : active;
        if (tempBool) {
          ele.setAttribute('aria-hidden', true);
        } else {
          ele.removeAttribute('aria-hidden');
        }
      }
    };
    modalActive();
    if (active) {
      const container = document.querySelector('.modal-container');
      const len = container.children.length;
      for (let i = 0; i < len; i++) {
        const node = container.children[i];
        if (node.nodeName !== 'SCRIPT') {
          if (node === dom) node.removeAttribute('aria-hidden');
          else node.setAttribute('aria-hidden', true);
        }
      }
    }
  }
  open(openBtn) {
    if (openBtn !== undefined) this._openButton = openBtn; // 로드 이벤틀등에 의해서 들어오면 오픈 타겟 지정
    this._open();
  }

  close() {
    this._close();
  }

  getElement() {
    return this._element;
  }

  _setupConfog(config) {
    this._config = {
      ...defaultConfig,
      ...Dialog.GLOBAL_CONFIG,
      ...config,
      ...dataSetToObject(this._element, dataAttrConfig, 'dialog'),
    };
  }
  _getActiveFirstItem() {
    const activeItem = this._element.querySelector(`[${IDENTIFIER.FIRST_FOCUS}]`);
    if (activeItem !== null) return activeItem;
    return this._element;
    // const itemlists = this._element.querySelectorAll(detachFocusTarget);
    // const len = itemlists.length;
    // for (let i = 0; i < len; i++) {
    //   const item = itemlists[i];
    //   if (isVisible(item)) return item;
    // }
    // return null;
  }
  _init() {}

  _initVars() {
    this._bg = this._config.bg ? this._createBackground() : null;
    this._closeButtons = this._element.querySelectorAll(`[${IDENTIFIER.CLOSE}]`);
  }

  _initEvents() {
    this._closeButtons.forEach(el => {
      EventHandler.one(el, super._eventName('click'), this._close.bind(this));
    });
    if (this._element.getAttribute(IDENTIFIER.CLOSE) !== null) {
      EventHandler.one(this._element, super._eventName('click'), this._bodyClose.bind(this));
    }
  }

  _removeEvents() {
    if (this._element.getAttribute(IDENTIFIER.CLOSE) !== null) {
      EventHandler.off(this._element, super._eventName('click'));
    }
    if (this._closeButtons === null) return;
    this._closeButtons.forEach(el => {
      EventHandler.off(el, super._eventName('click'));
    });
  }

  _removeVars() {
    this._bg = null;
    this._closeButtons = null;
    this._openButton = null;
  }

  _createBackground() {
    return toHTML(this._config.bgTemplate);
  }

  _zIndexOrderIncrease() {
    if (this._config.zIndexIgnore === true) return;
    this._element.style.zIndex = ZINDEX.CONTENT += ZINDEX.INCREASE;
    if (this._bg) this._bg.style.zIndex = ZINDEX.BACKDROP += ZINDEX.INCREASE;
  }

  _open() {
    if (this._isOpen === true) return;

    // console.log('open', this._element.querySelector('.c-modal__dialog').scrollTop);
    // this._ariaHiddenCheck(true);
    this._initVars();
    this._initEvents();
    this._zIndexOrderIncrease();
    this._showBackground();
    this._showDialog();
    if (this._element.classList.contains('msg-type')) {
      this._bg.style.backgroundColor = 'rgba(0, 0, 0, 0.85)';
    }
    // modify: 김원욱 - 팝업 활성화 비활성화를 위한 포커스 처리
    if (event !== undefined && event.type === 'click') this._openButton = event.target; // 이벤트를 발생한 타겟이 다이얼로그가 닫혔을 경우 포커스로 돌아와야 하기 때문에
    this._isOpen = true;

    EventHandler.trigger(this._element, Dialog.EVENT.OPEN, {
      component: this,
    });
    const focusTarget = this._getActiveFirstItem();
    if (this._config.banFocus === false) {
      if (focusTarget === null) {
        this._element.focus();
      } else {
        focusTarget.focus();
      }
    }

    Dialog.OpenPopEvent(this._element);
    this._element.querySelector('.c-modal__content').scrollTop = 0;
  }
  _bodyClose(e) {
    if (e.target !== this._element) return;
    this._close(e);
  }
  _close(e) {
    if (e !== undefined) e.preventDefault();
    if (this._openButton !== null) this._openButton.focus();

    this._hideDialog();
    // this._ariaHiddenCheck(false);
    EventHandler.trigger(this._element, Dialog.EVENT.CLOSE, {
      component: this,
    });
    if (this._config.animate !== true) {
      this._hideBackground();
      this.destroy();
    }
    // scroll 갱신
    // document.body.removeAttribute('stlye');
  }

  destroy() {
    Dialog.ClosePopEvent(this._element);
    this._removeEvents();
    this._removeVars();
    this._isOpen = false;
    if (this._config.destroy === true || this._config.destroy === 'true') {
      this._element.parentNode.removeChild(this._element);
      super.destroy();
    }
  }

  _showBackground() {
    if (this._bg) {
      document.body.appendChild(this._bg);
      // eslint-disable-next-line no-unused-vars
      const reflow = this._bg.offsetHeight;
      this._bg.classList.add('fadeIn');
    }
  }

  _showDialog() {
    // eslint-disable-next-line no-unused-vars
    const reflow = this._element.offsetHeight;
    if (this._config.animate === true) {
      EventHandler.one(this._element, 'animationend', () => {
        EventHandler.trigger(this._element, Dialog.EVENT.OPENED, {
          component: this,
        });
      });
    }
    this._element.classList.add(this._config.openClass);
  }

  _hideBackground() {
    if (this._bg) document.body.removeChild(this._bg);
  }

  _hideDialog() {
    // -------------------------------------------------
    // Dialog close 후처리 함수 실행
    // -------------------------------------------------
    if (this._element.attributes.role_fn) {
      for (var id of this._element.attributes) {
        if (id.name.indexOf('role_fn') > -1) {
          var funcId = id.nodeValue || '';
          var execFunc = new Function('return ' + funcId)();
          if (typeof execFunc == 'function') {
            execFunc();
          }
        }
      }
    }

    this._element.classList.add(this._config.closeClass);
    this._element.classList.remove(this._config.openClass);
    EventHandler.one(this._element, 'animationend', () => {
      this._element.classList.remove(this._config.closeClass);
      this._hideBackground();
      EventHandler.trigger(this._element, Dialog.EVENT.CLOSED, {
        component: this,
      });
      this.destroy();
    });
  }
}

EventHandler.on(document, `click.DIALOG_TRIGGER`, event => {
  const el = event.target.closest(`[${IDENTIFIER.TRIGGER}]`);
  if (!el || el.getAttribute('disabled')) return;
  const target = el.getAttribute(IDENTIFIER.TRIGGER);
  if (target) {
    const dialogElement = getElement(target);
    if (dialogElement) {
      const dialog = Dialog.getInstance(dialogElement);
      if (dialog) {
        dialog.open();
      } else {
        new Dialog(dialogElement).open();
      }
    }
  }
});

export { defaultConfig };
export default Dialog;
