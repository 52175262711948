import UI from './ui-base';
import EventHandler from '../_dependencyvendor/event-handler';
import { toHTML, dataSetToObject } from '../util/dom-util';

// eslint-disable-next-line no-unused-vars
const VERSION = '0.0.1';
const NAME = 'ui.tooltip';

const IDENTIFIER = {
  CLOSE: 'data-tooltip-close',
  TARGET: 'data-tooltip-target',
};

const dataAttrConfig = {
  action: 'click', // 'hover'
  /**
   * xl ty: left, top
   * xl yc: left, center,
   * xl yb: left, bottom
   * --------------------
   * xc yt: center, top
   * xc yc: center, center
   * xc yr: center, right
   * --------------------
   * xr ty: right, top
   * xr yc: right, center
   * xr yb: rignt, bottom
   */
  position: 'xc yb',
  offset: [10, 10],
  container: null,
  goback: true,
};

const defaultConfig = {
  templateRelacer: {},
  ...dataAttrConfig,
};

/**
 * @todo position offset 처리 해야 함
 */
class Tooltip extends UI {
  constructor(element, config = {}) {
    super(element, config);
    if (Tooltip.template === '') {
      super._throwError(`템플릿이 설정되지 않았습니다!`);
    }
    this._tooltipElement = null;
    this._tooltipContentID = '';
    this._setupConfog(config);
    this._container = this._getContainer();
    this._init();
  }

  static template = ``;

  static get NAME() {
    return NAME;
  }

  show() {
    this._show();
  }

  close() {
    this._close();
  }

  /**
   * 툴팁은 항상 즉시 초기화 한다.
   */
  _init() {
    this._initEvents();
  }

  _getTemplate() {
    return Tooltip.template;
  }

  _getContainer() {
    const { container } = this._config;
    let appendContainer = container === null ? window : container;
    if (typeof appendContainer === 'string') {
      appendContainer = document.querySelector(appendContainer);
    }
    return appendContainer;
  }

  _createTooltipElement() {
    const { replacer } = this._config;
    // 툴팁 컨텐츠 ID
    const contentID = super._getRandomSerial();

    let template = this._getTemplate();
    for (const key in replacer) {
      if (Object.prototype.hasOwnProperty.call(replacer, key)) {
        const dataAttrValue = this._element.getAttribute([key]);
        template = template.replace(replacer[key], dataAttrValue);
      }
    }
    this._tooltipElement = toHTML(template);
    // 툴팁 오프너와 툴팁 컨텐츠를 aria-describedby와 id로 연결시켜준다.
    this._tooltipElement.setAttribute('id', contentID);
    this._element.setAttribute('aria-describedby', contentID);
    if (this._container === window) {
      document.body.appendChild(this._tooltipElement);
    } else {
      this._container.appendChild(this._tooltipElement);
    }
  }

  _setupConfog(config) {
    this._config = {
      ...defaultConfig,
      ...config,
      ...dataSetToObject(this._element, dataAttrConfig, 'tp'),
    };
  }

  _initEvents() {
    EventHandler.on(this._element, super._eventName('click'), this._openHandler.bind(this));
  }

  /**
   * 툴팁 오픈
   */
  _show() {
    // 툴팁 엘리먼트 생성
    this._createTooltipElement();
    this._updatePosition();
    setTimeout(() => {
      this._addTooltipContentEvents();
    }, 0);
  }

  /**
   * 툴팁 닫기
   */
  _close() {
    this._removeTooltipContentEvents();
    this._tooltipElement.parentNode.removeChild(this._tooltipElement);
  }

  /**
   * 툴팁이 오픈되기 전에는 이벤트를 등록하지 않고,
   * 툴팁이 오픈되는 순간 필요한 모든 이벤트를 바인딩 한다.
   */
  _addTooltipContentEvents() {
    const closeButtons = this._tooltipElement.querySelectorAll(`[${IDENTIFIER.CLOSE}]`);
    // 툴팁 닫기 버튼으로 설정된 버튼들에 닫기 이벤트 셋팅
    closeButtons.forEach(el => {
      EventHandler.on(el, super._eventName('click'), this._closeHandler.bind(this));
    });
    // window 영역 클릭 시 툴팁 닫히지만, 툴팁 영역을 클릭하면 이벤트 전파를 끊어 닫히지 않게 처리한다.
    EventHandler.on(this._tooltipElement, super._eventName('click'), event => {
      event.stopPropagation();
    });
    // window 영역 클릭 시 툴팁 닫기
    EventHandler.on(window, super._eventName('click'), this._close.bind(this));
    // 화면 리사이즈 시 툴팁 포지션 업데이트
    EventHandler.on(window, super._eventName('resize'), this._updatePosition.bind(this));
    // 컨테이너 스크롤 시 툴팁 삭제
    EventHandler.on(this._container, super._eventName('scroll'), () => {
      this._close();
    });
  }

  /**
   * 툴팁 컨텐츠에 등록되었던 모든 이벤트 해제
   */
  _removeTooltipContentEvents() {
    const closeButtons = this._tooltipElement.querySelectorAll(`[${IDENTIFIER.CLOSE}]`);
    closeButtons.forEach(el => {
      EventHandler.off(el, super._eventName('click'));
    });
    EventHandler.off(this._tooltipElement, super._eventName('click'));
    EventHandler.off(window, super._eventName('click'));
    EventHandler.off(window, super._eventName('resize'));
    EventHandler.off(this._container, super._eventName('scroll'));
  }

  _getCurrentStageInfo() {
    const info = {
      width: 0,
      height: 0,
      scrollLeft: 0,
      scrollTop: 0,
    };
    if (this._container === window) {
      info.width = window.innerWidth;
      info.height = window.innerHeight;
      info.scrollLeft = window.pageXOffset;
      info.scrollTop = window.pageYOffset;
    } else {
      info.width = this._container.offsetWidth;
      info.height = this._container.offsetHeight;
      info.scrollLeft = this._container.scrollLeft;
      info.scrollTop = this._container.scrollTopl;
    }
    return info;
  }

  /**
   * X 축, Y축 검사하며
   * 툴팁이 짤리는 경우를 검사하여
   * 알맞는 포지션으로 변환하며 반환한다.
   *
   * XR(X축 Right가 화면에 짤리게 될 경우 -> XC로 변경, XC로 짤릴경우 XL로 변경)
   * Y축도 동일 로직으로 처리
   *
   * @param {*} positionName
   * @returns
   */
  _getPosition(positionName) {
    const opennerRect = this._element.getBoundingClientRect();
    const stage = this._getCurrentStageInfo();
    const tw = this._tooltipElement.offsetWidth;
    const th = this._tooltipElement.offsetHeight;
    const screenLeft = stage.scrollLeft;
    const screenRight = stage.width + stage.scrollLeft;
    const screenTop = stage.scrollTop;
    const screenBottom = stage.height + stage.scrollTop;
    const opennerWidth = opennerRect.width;
    const opennerHeight = opennerRect.height;
    const opennerLeft = opennerRect.left + window.pageXOffset;
    const opennerTop = opennerRect.top + window.pageYOffset;
    const opennerBottom = opennerTop + opennerHeight;
    const opennerRight = opennerLeft + opennerWidth;
    const opennerXCenter = opennerLeft + opennerWidth / 2;
    const opennerYCenter = opennerTop + opennerHeight / 2;
    let calcPositionValue = 0;
    switch (positionName) {
      // x축 - left
      case 'XL':
        calcPositionValue = opennerLeft - tw;
        if (calcPositionValue < screenLeft) calcPositionValue = this._getPosition('XC');
        break;
      // x축 - center
      case 'XC':
        calcPositionValue = opennerXCenter - tw / 2;
        if (calcPositionValue < screenLeft) calcPositionValue = this._getPosition('XR');
        if (calcPositionValue + tw > screenRight) calcPositionValue = this._getPosition('XL');
        break;
      // x축 - right
      case 'XR':
        calcPositionValue = opennerRight;
        if (calcPositionValue + tw > screenRight) calcPositionValue = this._getPosition('XC');
        break;
      // y축 - top
      case 'YT':
        calcPositionValue = opennerTop - th;
        if (calcPositionValue < screenTop) calcPositionValue = this._getPosition('YC');
        break;
      // y축 - center
      case 'YC':
        calcPositionValue = opennerYCenter - th / 2;
        if (calcPositionValue < screenTop) calcPositionValue = this._getPosition('YB');
        if (calcPositionValue + th > screenBottom) calcPositionValue = this._getPosition('YT');
        break;
      // y축 - bottom
      case 'YB':
        calcPositionValue = opennerBottom;
        if (calcPositionValue + th > screenBottom) calcPositionValue = this._getPosition('YC');
        break;
    }
    return calcPositionValue;
  }

  /**
   * 툴팁 포지션 업데이트
   */
  _updatePosition() {
    const { position } = this._config;
    const positions = position.split(' ');
    const positionX = positions[0];
    const positionY = positions[1];
    const resultX = this._getPosition(positionX.toUpperCase());
    const resultY = this._getPosition(positionY.toUpperCase());

    Object.assign(this._tooltipElement.style, {
      left: `${resultX}px`,
      top: `${resultY}px`,
    });
  }

  _openHandler(event) {
    event.preventDefault();
    this.show();
  }
  _closeHandler(event) {
    event.preventDefault();
    this._close();
  }
}

export default Tooltip;
