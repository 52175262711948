import { toHTML } from '../util/dom-util';

// eslint-disable-next-line no-unused-vars
const VERSION = '1.0.0';
const NAME = 'ui.loading-lazy';

class LazyLoading {
  constructor() {
    this._showCount = 0;
    this._template = toHTML(`
    <div class="c-loader__wrap">
      <div class="c-loader c-loader--lazy">
        <svg class="lazy" xmlns="htttp://www.w3.org/2000/svg" width="56" height="12" viewBox="0 0 56 12" fill="none">
          <circle class="load-dot-1" cx="6" cy="6" r="6" fill="#d31145">
            <!-- <animate attributeName="r" begin="0s" calcMode="linear" dur="0.8s" from="6" repeatCount="indefinite" to="6" values="6;5;4" /> -->
          </circle>
          <circle class="load-dot-2" cx="6" cy="6" r="6" fill="#d31145">
            <!-- <animate attributeName="r" begin="0s" calcMode="linear" dur="0.8s" from="5" repeatCount="indefinite" to="5" values="5;4;6" /> -->
          </circle>
          <circle class="load-dot-3" cx="6" cy="6" r="6" fill="#d31145">
            <!-- <animate attributeName="r" begin="0s" calcMode="linear" dur="0.8s" from="4" repeatCount="indefinite" to="4" values="4;6;5" /> -->
          </circle>
        </svg>
      </div>
    </div>
    `);
  }

  static get NAME() {
    return NAME;
  }

  show() {
    if (this._showCount > 0) return;
    document.body.appendChild(this._template);
    this._showCount++;
    AIA.Dialog.OpenPopEvent(this._template);
  }

  hide() {
    this._showCount--;
    if (this._showCount < 1) {
      document.body.removeChild(this._template);
    }
    AIA.Dialog.ClosePopEvent(this._template);
  }
}

export default new LazyLoading();
