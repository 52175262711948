/* eslint-disable no-unused-vars */
import { dataSetToObject } from '../util/dom-util';
import EventHandler from '../_dependencyvendor/event-handler';
import UI from './ui-base';

// eslint-disable-next-line no-unused-vars
const VERSION = '1.0.0';
const NAME = 'ui.tooltip-box';

// 고정 식별자로 쓸 data-attr
const IDENTIFIER = {
  TARGET: 'data-tpbox',
  CLOSE: 'data-tpbox-close',
};

const defaultConfig = {
  showClass: 'active',
  hideClass: 'deactive',
  animate: true,
};

class TooltipBox extends UI {
  constructor(element, config = {}) {
    super(element, config);
    this._tooltipBox = null;
    this._closeButton = null;
    this._tooltipBoxName = '';
    this._setupConfog(config);
    this._init();
  }

  static GLOBAL_CONFIG = {};
  static get NAME() {
    return NAME;
  }

  // public method

  destroy() {
    this._removeEvents();
    this._removeVars();
    super.destroy();
  }

  _setupConfog(config) {
    this._config = {
      ...defaultConfig,
      ...TooltipBox.GLOBAL_CONFIG,
      ...config,
    };
  }

  _init() {
    this._initVars();
    this._initEvents();
  }

  /**
   * 변수 초기화
   */
  _initVars() {
    this._tooltipBoxName = this._element.getAttribute(IDENTIFIER.TARGET);
    this._tooltipBox = document.querySelector(this._tooltipBoxName);
    if (!this._tooltipBox) {
      this._throwError(`${this._tooltipBoxName} -> does not match any content element!`);
    }
    this._closeButton = this._tooltipBox.querySelector(`[${IDENTIFIER.CLOSE}]`);
  }

  /**
   * 이벤트 초기화
   */
  _initEvents() {
    EventHandler.on(this._element, this._eventName('click'), e => {
      e.preventDefault();
      if (this._tooltipBox.classList.contains(this._config.showClass)) return;
      this._show();
      setTimeout(() => {
        EventHandler.on(window, this._eventName('click'), e => {
          if (!e.target.closest(this._tooltipBoxName)) {
            this._hide();
          }
        });
      }, 0);
    });
    if (this._closeButton) {
      EventHandler.on(this._closeButton, this._eventName('click'), e => {
        e.preventDefault();
        this._hide();
      });
    }
  }

  /**
   * 변수 초기화
   */
  _removetVars() {
    EventHandler.off(this._element, this._eventName('click'));
    if (this._closeButton) {
      EventHandler.off(this._closeButton, this._eventName('click'));
    }
  }

  /**
   * 이벤트 초기화
   */
  _removeEvents() {}

  _show() {
    this._tooltipBox.classList.add(this._config.showClass);
    if (this._config.hideClass) {
      this._tooltipBox.classList.remove(this._config.hideClass);
    }
  }

  _hide() {
    this._tooltipBox.classList.remove(this._config.showClass);
    if (this._config.hideClass) {
      this._tooltipBox.classList.add(this._config.hideClass);
      if (this._config.animate) {
        EventHandler.one(this._tooltipBox, 'animationend', () => {
          this._tooltipBox.classList.remove(this._config.hideClass);
        });
      }
    }
    EventHandler.off(window, this._eventName('click'));
  }
}

export default TooltipBox;
